<template>
  <section class="g-collapsible" :class="{ open: isOpen }" ref="collapsible">
    <button class="header-button" @click="toggle">
      <div class="header">
        <slot name="title">
          <div class="title">{{ title }}</div>
        </slot>
        <slot v-if="!disableToggle" name="icon">
          <div class="icon">
            <IconCarat />
          </div>
        </slot>
      </div>
    </button>
    <CollapseTransition v-bind="{ ...$props }" v-on="$listeners">
      <div class="collapse-wrapper" v-show="isOpen">
        <div class="content">
          <slot />
        </div>
      </div>
    </CollapseTransition>
  </section>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import IconCarat from './assets/carat.svg';

/**
 * @version 0.1.1
 */
export default {
  name: 'GCollapsible',
  extends: CollapseTransition,
  components: {
    CollapseTransition,
    IconCarat,
  },
  model: {
    prop: 'open',
    event: 'update:open',
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    // QUESTION: Should this be called expand? or open?
    open: {
      type: Boolean,
      default: false,
    },
    disableToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.open,
    };
  },
  watch: {
    open(value) {
      this.isOpen = value;
    },
  },
  mounted() {
    this.$refs.collapsible.style.setProperty('--g-collapsible-duration', `${this.duration}ms`);
  },
  methods: {
    toggleOpen() {
      this.isOpen = true;
      this.$emit('update:open', this.isOpen);
      this.$emit('open');
    },
    toggleClose() {
      this.isOpen = false;
      this.$emit('update:open', this.isOpen);
      this.$emit('close');
    },
    toggle() {
      if (!this.disableToggle) {
        this.isOpen = !this.isOpen;
        this.$emit('toggle', this.isOpen);
        this.$emit('update:open', this.isOpen);
        if (this.isOpen) {
          this.$emit('open');
        } else {
          this.$emit('close');
        }
      }
    },
  },
};
</script>

<style lang="scss">
.g-collapsible {
  .header-button {
    width: 100%;
    padding: 0;
    margin: 0;
    color: inherit;
    text-align: left;
    background: none;
    border: none;
  }
  .header {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      vertical-align: middle;
    }
    .icon {
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-left: auto;
      transition: transform var(--g-collapsible-duration);
      transform-origin: center;
      will-change: transform;
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &.open {
    .icon {
      transform: rotate(180deg);
    }
  }
  .content {
    will-change: height;
  }
}
</style>
