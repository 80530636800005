import { addCollection } from '@iconify/vue2';

const icons = {
  prefix: 'g',
  icons: {
    arrow: {
      body: '<path fill="currentColor" d="M23.51 44.012 2.81 23.753a1.252 1.252 0 010-1.746l20.7-20.259L21.8.001 1.1 20.26a3.666 3.666 0 000 5.242l20.7 20.259z" class="fill"/>',
      width: 23.51,
      height: 45.759,
    },
    carat: {
      body: '<g fill="currentColor"><defs><clipPath id="svgIDa"><path fill="none" d="M0 0h19.838v10.193H0z"/></clipPath></defs><g clip-path="url(#svgIDa)"><path d="m19.081 0-8.783 8.976a.543.543 0 01-.757 0L.758.003 0 .744l8.783 8.973a1.59 1.59 0 002.273 0L19.839.744Z"/></g></g>',
      width: 19.838,
      height: 10.193,
    },
    checkmark: {
      body: '<path fill="currentColor" stroke="currentColor" stroke-width=".5" d="M4.413 11.538a.375.375 0 01-.266-.11l-3.75-3.75a.375.375 0 01.53-.53l3.486 3.486L14.648.397a.375.375 0 01.53.531l-10.5 10.5a.369.369 0 01-.265.11Z"/>',
      width: 15.538,
      height: 11.788,
    },
    'close-x': {
      body: '<path fill="currentColor" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm4.151 17.943-4.143-4.102L7.891 18l-1.833-1.833 4.104-4.157L6 7.891l1.833-1.833 4.155 4.102L16.094 6l1.849 1.849-4.1 4.141L18 16.094l-1.849 1.849z"/>',
    },
    'error-icon': {
      body: '<path fill="currentColor" d="M25 0A25 25 0 110 25 25 25 0 0125 0Z"/><g fill="currentColor"><path d="m32.627 30.721-6.444-5.727 6.444-5.73a.79.79 0 00-1.049-1.182l-6.582 5.852-6.583-5.849a.79.79 0 00-1.049 1.179l6.443 5.73-6.443 5.727a.79.79 0 101.049 1.181l6.583-5.851 6.582 5.851a.79.79 0 001.049-1.181"/><path d="M25 40a15 15 0 1110.61-4.394A15 15 0 0125 40m0-28.426A13.423 13.423 0 1038.42 25 13.423 13.423 0 0025 11.574Z"/></g>',
      width: 50,
      height: 50,
    },
    eye: {
      body: '<path fill="currentColor" d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449S19.748 20 12.015 20C4.835 20 0 11.551 0 11.551S4.446 4 12.015 4C19.709 4 24 11.551 24 11.551zM17 12c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/>',
    },
    'eye-off': {
      body: '<path fill="currentColor" d="m11.885 14.988 3.104-3.098L15 12c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972C19.748 20 24 11.551 24 11.551s-1.415-2.478-4.067-4.595zm1.431-3.536L2.745 22l-1.382-1.422 3.455-3.447C1.796 14.681 0 11.551 0 11.551S4.446 4 12.015 4c1.825 0 3.456.426 4.886 1.075L19.982 2l1.382 1.42zM7.613 14.342l1.519-1.515A2.938 2.938 0 019 12c0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515A4.906 4.906 0 0012 7c-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z"/>',
    },
    'success-icon': {
      body: '<path fill="currentColor" d="M25 0A25 25 0 110 25 25 25 0 0125 0Z"/><g fill="currentColor"><path d="M25 40a15 15 0 1110.61-4.393A15 15 0 0125 40m0-28.426A13.423 13.423 0 1038.42 25 13.423 13.423 0 0025 11.574Z"/><path d="M21.837 31.314a.79.79 0 01-.559-.232l-4.74-4.74a.794.794 0 011.123-1.123l4.178 4.18 10.495-10.5a.794.794 0 011.123 1.123L22.402 31.079a.778.778 0 01-.559.232Z"/></g>',
      width: 50,
      height: 50,
    },
    'thumbnail-image': {
      body: '<g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="6" transform="translate(-348 -253)"><rect width="116" height="82" class="stroke" rx="2" transform="translate(351 284)"/><path d="M369.476 272.166v-11.547a4.618 4.618 0 014.619-4.619H480.33a4.618 4.618 0 014.619 4.619v73.9a4.618 4.618 0 01-4.619 4.619h-2.309" class="stroke"/><circle cx="7.5" cy="7.5" r="7.5" class="stroke" transform="translate(364 302)"/><path d="m397.417 336.539-7.156-8.945-31.409 39.261m102.999-.001-36.949-46.189-36.951 46.189" class="stroke"/></g>',
      width: 139.949,
      height: 118.071,
    },
    'thumbnail-pdf': {
      body: '<path fill="currentColor" d="M67.425 17.399H56.55V6.525a6.533 6.533 0 00-6.525-6.526H17.398a2.176 2.176 0 00-1.54.64L.633 15.864a2.154 2.154 0 00-.635 1.535v45.675a6.533 6.533 0 006.525 6.525h10.875v10.875a6.533 6.533 0 006.527 6.525h43.5a6.533 6.533 0 006.525-6.525v-56.55a6.533 6.533 0 00-6.525-6.525ZM17.398 5.251v9.974a2.176 2.176 0 01-2.175 2.175H5.248Zm-10.875 60a2.176 2.176 0 01-2.175-2.175V21.75h10.875a6.533 6.533 0 006.525-6.525V4.35h28.275a2.176 2.176 0 012.175 2.175v10.874h-17.4a2.177 2.177 0 00-1.54.639L18.033 33.263a2.154 2.154 0 00-.635 1.535v30.45Zm28.275-42.6v9.974a2.176 2.176 0 01-2.175 2.175h-9.975Zm34.8 57.824a2.176 2.176 0 01-2.175 2.175h-43.5a2.176 2.176 0 01-2.175-2.175V39.15h10.875a6.533 6.533 0 006.525-6.525V21.75h28.275a2.176 2.176 0 012.175 2.175Z" class="fill"/>',
      width: 73.95,
      height: 87,
    },
    'thumbnail-ppt': {
      body: '<path fill="currentColor" d="M68.256-.001H1.845A1.845 1.845 0 00-.002 1.845v55.343a1.846 1.846 0 001.845 1.845h28.755L18.991 70.644a1.844 1.844 0 002.612 2.6L33.21 61.637v10.3a1.845 1.845 0 003.689 0v-10.3l11.607 11.607a1.844 1.844 0 002.612-2.6L39.511 59.033h28.752a1.845 1.845 0 001.845-1.845V1.845a1.845 1.845 0 00-1.844-1.846ZM3.689 3.688h62.722v44.275H3.69Zm62.722 51.654H3.69v-3.689h62.722Z" class="fill"/>',
      width: 70.108,
      height: 73.79,
    },
    'thumbnail-video': {
      body: '<style>.st0{fill:currentColor}</style><path d="M77.9 0H3.1C1.4 0 0 1.3 0 3v51.5c0 1.7 1.4 3 3.1 3H78c1.7 0 3.1-1.3 3.1-3V3c-.1-1.7-1.5-3-3.2-3zM2.1 15.4h14.4l.1.2.2-.2h62v39.1c0 .5-.4 1-1 .9H3.1c-.5 0-.9-.4-1-.9V15.4zm0-2.1V3c0-.5.4-.9 1-.9h3l8.8 11.3H2.1zm15.5 0L8.8 2.1h11.7l8.8 11.3H17.6zm14.3 0L23.1 2.1h11.8l8.8 11.3H31.9zm14.5 0L37.5 2.1h11.8l8.8 11.3H46.4zm14.4 0L52 2.1h11.8l8.8 11.3H60.8zm18.1 0h-3.6L66.4 2.1h11.5c.5 0 .9.4 1 .9v10.3z" class="st0"/><path d="M25.7 39c0 .1.1.1.2.1h.1c.1 0 .2-.1.2-.1l3.8-8.1c.1-.2 0-.3-.2-.3h-1.6c-.1 0-.2.1-.2.1l-2 4.4h-.1l-2-4.4c0-.1-.1-.1-.2-.1h-1.6c-.2 0-.3.2-.2.3l3.8 8.1zm5.5 0h1.4c.1 0 .2-.1.2-.2v-7.9c0-.1-.1-.2-.2-.2h-1.4c-.1 0-.2.1-.2.2v7.9c0 .1.1.2.2.2zm4 0h2.9c2.3 0 4.2-1.9 4.2-4.2 0-2.3-1.9-4.2-4.2-4.2h-2.9c-.1 0-.2.1-.2.2v7.9c-.1.2 0 .3.2.3zm1.6-6.7H38c1.4 0 2.3 1.1 2.3 2.4 0 1.4-1 2.4-2.3 2.4h-1.2v-4.8zM44 39h5c.1 0 .2-.1.2-.2v-1.3c0-.1-.1-.2-.2-.2h-3.3v-1.6h2.7c.1 0 .2-.1.2-.2v-1.3c0-.1-.1-.2-.2-.2h-2.7v-1.5H49c.1 0 .2-.1.2-.2V31c0-.1-.1-.2-.2-.2h-5c-.1 0-.2.1-.2.2v7.9s.1.1.2.1zm10.5.1c2.4 0 4.3-1.9 4.3-4.3s-1.9-4.3-4.3-4.3-4.3 1.9-4.3 4.3 1.9 4.3 4.3 4.3zm0-6.7c1.3 0 2.4 1.1 2.4 2.4 0 1.3-1.1 2.4-2.4 2.4-1.3 0-2.4-1.1-2.4-2.4 0-1.3 1.1-2.4 2.4-2.4z" class="st0"/>',
      width: 81,
      height: 57.5,
    },
    'thumbnail-website': {
      body: '<style>.st0{fill:currentColor}</style><path d="M28.3 43.1h4.5c.1 0 .2-.1.2-.2v-1.3c0-.1-.1-.2-.2-.2h-2.9V35c0-.1-.1-.2-.2-.2h-1.4c-.1 0-.2.1-.2.2v7.9c-.1.1 0 .2.2.2zm6.1 0h1.4c.1 0 .2-.1.2-.2V35c0-.1-.1-.2-.2-.2h-1.4c-.1 0-.2.1-.2.2v7.9c0 .1.1.2.2.2zm4 0h1.4c.1 0 .2-.1.2-.2v-4.5l4.7 4.8s.1.1.2.1h.2c.1 0 .2-.1.2-.2V35c0-.1-.1-.2-.2-.2h-1.4c-.1 0-.2.1-.2.2v4.3l-4.8-4.6h-.3c-.1 0-.2.1-.2.2V43c-.1 0 0 .1.2.1zm9.3 0h1.4c.2 0 .3-.1.3-.3v-3.6l2.8 3.8s.1.1.2.1H54c.2 0 .4-.3.2-.4l-3.1-4 2.8-3.5c.1-.2 0-.4-.2-.4h-1.5c-.1 0-.2.1-.2.1l-2.7 3.3V35c0-.2-.1-.3-.3-.3h-1.4c-.2 0-.3.1-.3.3v7.8c.1.2.2.3.4.3z" class="st0"/><path d="M77.3 0H3.7C1.6 0 0 1.7 0 3.7v60.1c0 2 1.6 3.7 3.7 3.7h73.6c2 0 3.7-1.7 3.7-3.7V3.7c0-2-1.7-3.7-3.7-3.7zM79 13.6H2V3.7C2 2.8 2.8 2 3.7 2h73.6c.9 0 1.7.8 1.7 1.7v9.9zm-77 2h77v48.2c0 .9-.7 1.7-1.7 1.7H3.7c-.9 0-1.7-.7-1.7-1.7V15.6z" class="st0"/><path d="M8.9 7.3c0-.1-.1-.3-.2-.3-.3-.3-.7-.5-1-.5-.2 0-.3 0-.4.1h-.1c-.1 0-.3.1-.3.2l-.1.1c-.1.1-.2.3-.2.4v.1c-.1.1-.2.2-.2.4s0 .3.1.4v.1c0 .1.1.3.2.3l.1.1c.3.3.6.4.9.4.2 0 .3 0 .5-.1h.1c.1 0 .3-.1.4-.2l.1-.1c.1-.1.2-.2.2-.3v-.6c0-.2 0-.3-.1-.5zm6.9 0c0-.1-.1-.3-.2-.3h-.1c-.2-.2-.5-.4-.9-.4s-.7.1-.9.3h-.1c-.1.1-.2.2-.2.3v.1c-.1.1-.1.3-.1.5 0 .4.1.7.4.9l.1.1s.2.1.3.2h.1c.1.1.3.1.5.1s.3 0 .5-.1h.1c.1 0 .3-.1.4-.2l.1-.1c.1-.1.2-.2.2-.3v-.1c.1-.1.1-.3.1-.5-.2-.2-.2-.3-.3-.5zm6.9 0c0-.1-.1-.3-.2-.4l-.1-.1c-.1-.1-.2-.2-.3-.2-.2-.1-.3-.1-.5-.1s-.3 0-.5.1c-.1 0-.3.1-.3.2l-.1.1c-.1.1-.2.2-.2.3v.1c-.1.1-.1.3-.1.5s0 .3.1.4v.1c0 .1.1.3.2.3l.1.1c.1.1.2.2.3.2L21 9c.1.1.3.1.5.1s.3 0 .5-.1c.1 0 .3-.1.4-.2l.1-.1c.1-.1.2-.2.2-.3v-.1c.1-.1.1-.3.1-.5s-.1-.3-.1-.5z" class="st0"/>',
      width: 81,
      height: 67.5,
    },
    'user-silhouette': {
      body: '<g fill="transparent" stroke="currentColor"><circle cx="10.548" cy="10.548" r="10.548" transform="rotate(-7.47 97.67 -95.504)"/><path d="M25.07 38.556c-7.445 0-13.108 1.86-16.505 5.385a25.048 25.048 0 0033.01 0c-3.398-3.525-9.057-5.385-16.505-5.385z"/><path d="M12.403 38.512a36.053 36.053 0 0125.345 0 17.32 17.32 0 015.526 3.8 25.074 25.074 0 10-36.4 0 17.281 17.281 0 015.529-3.8zM25.075 8.083a12.892 12.892 0 11-12.892 12.892 12.906 12.906 0 0112.89-12.892z"/></g>',
      width: 50.147,
      height: 50.147,
    },
  },
  width: 25,
  height: 25,
};
addCollection(icons);
export default icons;
