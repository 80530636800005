<template>
  <span class="g-divider" :class="vertical ? 'vertical' : 'horizontal'" />
</template>

<script>
export default {
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.g-divider {
  --g-divider-color: currentColor;
  --g-divider-thickness: 1px;
  --g-divider-style: solid;

  border-color: var(--g-divider-color);
  border-width: var(--g-divider-thickness);
  &.horizontal {
    display: block;
    width: 100%;
    border-bottom-style: var(--g-divider-style);
  }
  &.vertical {
    align-self: stretch;
    border-right-style: var(--g-divider-style);
  }
}
</style>
