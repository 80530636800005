<template>
  <component
    :is="'GDatePicker'"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    class="gk-datepicker"
    @input="$emit('validate', $event)"
  >
    <template v-slot="{ inputValue, inputEvents, updateValue }">
      <template v-if="$props.isRange">
        <slot name="startDate" v-bind="{ inputValue: inputValue.start, inputEvents: inputEvents.start, updateValue }">
          <StyledDatepickerInput
            :value="inputValue.start"
            :placeholder="placeholder.start || placeholder"
            :error="error"
            v-on="inputEvents.start"
            @clear="updateValue(null)"
          />
        </slot>
        <slot name="separator">
          <span class="separator">{{ separator }}</span>
        </slot>
        <slot name="endDate" v-bind="{ inputValue: inputValue.end, inputEvents: inputEvents.end, updateValue }">
          <StyledDatepickerInput
            :value="inputValue.end"
            :placeholder="placeholder.end || placeholder"
            :error="error"
            v-on="inputEvents.end"
            @clear="updateValue(null)"
          />
        </slot>
      </template>
      <StyledDatepickerInput
        v-else
        :value="inputValue"
        :error="error"
        :placeholder="placeholder"
        v-on="inputEvents"
        @clear="updateValue(null)"
      />
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import GDatePicker from 'v-calendar/lib/components/date-picker.umd';
import { StyledDatepickerInput } from '.';

export default {
  name: 'GKDatepicker',
  components: {
    GDatePicker,
    StyledDatepickerInput,
  },
  extends: GDatePicker,
  props: {
    placeholder: {
      type: [String, Object],
      default: 'Select Date',
    },
    color: {
      default: 'indigo',
    },
    error: {
      type: Boolean,
      default: false,
    },
    separator: {
      type: String,
      default: 'To',
    },
    popover: {
      default: () => ({
        visibility: 'focus',
      }),
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.gk-datepicker {
  display: flex;
  align-items: center;

  .datepicker-input {
    width: 100%;
  }

  .separator {
    margin: 25px;
    text-transform: uppercase;
  }
}
</style>
