<template>
  <component :is="'GRadio'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-radio">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GRadio } from '@grimoire/base';

export default {
  name: 'PassportRadio',
  inheritAttrs: false,
  extends: GRadio,
  components: {
    GRadio,
  },
};
</script>

<style lang="scss" scoped>
@import './assets/scss/colors';
//
</style>
