<template>
  <StyledInput
    v-model="query"
    class="gk-chips"
    ref="input"
    v-bind="{ ...$attrs, ...$props }"
    :placeholder="!chips.length ? $attrs.placeholder : undefined"
    @input="checkQuery"
    @keydown.enter="addTag"
    @blur="addTag"
    @keydown.delete="!disableBackspaceRemove ? removeTag() : null"
    @keyup.ctrl.90="undoAction"
    @keyup.ctrl.89.prevent
  >
    <template v-slot:iconLeft>
      <StyledTag v-for="(chip, index) in chips" :label="chip" :key="index" @remove="removeTag(index)" />
    </template>
  </StyledInput>
</template>

<script>
import { StyledInput, StyledTag } from '.';

export default {
  name: 'GKChips',
  extends: StyledInput,
  components: { StyledInput, StyledTag },
  props: {
    chips: {
      type: Array,
      default: () => [],
    },
    disableCommaSeparate: {
      type: Boolean,
      defalt: false,
    },
    disableBackspaceRemove: {
      type: Boolean,
      defalt: false,
    },
  },
  data() {
    return {
      query: '',
      undoStack: [],
      redoStack: [],
      blockUndoStack: false,
      blockRedoStack: false,
      initialLoad: true,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {
    // chips(newValue, oldValue) {
    //   if (!this.initialLoad) {
    //     if (!this.blockUndoStack) {
    //       this.undoStack.push(oldValue);
    //     }
    //     if (!this.blockRedoStack) {
    //       this.redoStack.push(newValue);
    //     }
    //   }
    //   this.initialLoad = false;
    //   this.blockNextStackStore = false;
    // },
  },
  methods: {
    undoAction() {
      if (this.undoStack.length) {
        const chips = this.undoStack.pop();
        this.updateTags(chips, false);
      }
    },
    redoTest() {
      if (this.redoStack.length) {
        // const chips = this.redoStack.pop();
        // this.undoStack.push(this.chips);
        // this.$emit('update:chips', chips);
      }
    },
    addTag() {
      if (!this.query || this.query === '') return;
      const chips = [...this.chips];
      chips.push(this.query);
      this.updateTags(chips);
      this.query = '';
    },
    updateTags(tags, addToStack = true) {
      if (addToStack) this.undoStack.push(this.chips);
      this.$emit('validate', tags);
      this.$emit('update:chips', tags);
    },
    removeTag(index) {
      if (!this.chips.length) return;
      const chips = [...this.chips];
      if (typeof index !== 'undefined') {
        chips.splice(index, 1);
      } else if (this.query === '') {
        chips.pop();
      }
      this.updateTags(chips);
      this.$refs.input.focus();
    },
    checkQuery(query) {
      let chips = [...this.chips];
      if (!this.disableCommaSeparate && query.includes(',')) {
        let keywords = query.split(',');
        if (keywords.length > 1) {
          keywords = keywords.filter((keyword) => keyword !== '').map((keyword) => keyword.trim());
          chips = [...chips, ...keywords];
        }
        this.$nextTick(() => {
          this.query = '';
        });
        this.updateTags(chips);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gk-chips {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  // width: 100%;
  .gk-tag {
    display: inline-flex;
    flex: 0 0 auto;
    margin: 2.5px;
  }
  ::v-deep {
    .input-element {
      display: inline-flex;
      flex: 1 1 auto;
      width: initial;
      height: 28px;
      padding: 5px;
    }
  }
}
</style>
