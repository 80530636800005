<template>
  <div class="g-field" :class="{ ...variant, error: hasError }">
    <div
      v-if="messagePosition === 'before' && (hasMessage || $scopedSlots.message)"
      class="message before"
      :class="{ ...variant, error: hasError }"
    >
      <slot name="message" v-bind="{ message: computedMessage }">
        {{ computedMessage }}
      </slot>
    </div>
    <slot name="label" v-bind="{ label }">
      <span v-if="label" class="label" :class="labelClass">
        {{ label }}
      </span>
    </slot>
    <slot v-if="helperTextPosition === 'before'" name="helperText">
      <div v-if="helperText" class="helper-text before" :class="helperTextClass">{{ helperText }}</div>
    </slot>
    <slot v-bind="{ hasError, updateValue, value }">
      <component
        :is="computedComponent"
        v-bind="{
          ...$attrs,
          ...inputOptions,
          type: inputType,
        }"
        :value="value"
        :error="hasError"
        v-on="$listeners"
      >
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>
      </component>
    </slot>
    <small v-if="maxlength && type !== 'number'">
      <slot name="count" v-bind="{ characterCount, maxLength: maxlength, charactersLeft }">
        <template v-if="countTemplate">{{ formattedCount }}</template>
        <template v-else-if="countType === 'descriptive'">
          {{ charactersLeft }} character{{ charactersLeft === 1 ? '' : 's' }} left
        </template>
        <!-- TODO add template option where I pass in a prop with {{}} and parse them on this end -->
        <template v-else> {{ characterCount }} / {{ maxlength }} </template>
      </slot>
    </small>
    <slot v-if="helperTextPosition === 'after'" name="helperText">
      <div v-if="helperText" class="helper-text after" :class="helperTextClass">{{ helperText }}</div>
    </slot>
    <div
      v-if="messagePosition === 'after' && (hasMessage || $scopedSlots.message)"
      class="message after"
      :class="{ ...variant, error: hasError }"
    >
      <slot name="message" v-bind="{ message: computedMessage }">
        {{ computedMessage }}
      </slot>
    </div>
  </div>
</template>

<script>
// TODO see about adding inputs directly to this component

/**
 * @version 1.0.0
 */

import { GInput, GCheckbox, GSwitch, GRadio, GDropdown } from '.';
// import baseComponentMixin from '@/components/local_modules/utils/baseComponentMixin';

export default {
  name: 'GField',
  // mixins: [baseComponentMixin],
  components: {
    GInput,
    GCheckbox,
    GSwitch,
    GRadio,
    GDropdown,
  },
  inheritAttrs: false,
  // Playing with Provide/Inject. Kind of goofy but this is how you make reactive in Vue 2. Better ways in Vue 3
  // I got this answer from here: https://stackoverflow.com/questions/65718651/how-do-i-make-vue-2-provide-inject-api-reactive
  // MDN says Object.defineProperty has good support https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty#browser_compatibility
  // Mixins perhaps would be better? Although then I think I would have to provide an additional file to include I think
  // provide() {
  //   return {
  //     fieldError: Object.defineProperty({}, 'value', {
  //       enumerable: true,
  //       get: () => this.hasError,
  //     }),
  //   };
  // },
  props: {
    value: {},
    el: { type: Object },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    labelClass: {
      type: [String, Array],
      default: null,
    },
    helperText: {
      type: String,
      default: null,
    },
    helperTextPosition: {
      type: String,
      default: 'after',
    },
    helperTextClass: {
      type: [String, Array],
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    inputOptions: {
      type: Object,
      default: null,
    },
    messagePosition: {
      type: String,
      default: 'after',
      validator(value) {
        return value === 'before' || value === 'after';
      },
    },
    inputComponent: {
      default: () => GInput,
    },
    checkboxComponent: {
      default: () => GCheckbox,
    },
    switchComponent: {
      default: () => GSwitch,
    },
    radioComponent: {
      default: () => GRadio,
    },
    dropdownComponent: {
      default: () => GDropdown,
    },
    maxlength: {
      type: [Number, String],
    },
    /**
     * outOf: currentCount / maxLength<br>
     * descriptive: (maxLength - currentCount) character(s) left
     */
    countType: {
      type: String,
      default: 'outOf',
      validator: (value) => ['outOf', 'descriptive'].includes(value),
    },
    countTemplate: {
      type: String,
    },
    rules: {
      type: Array,
    },
    fields: {
      type: Object,
    },
  },
  computed: {
    formattedCount() {
      let template = this.countTemplate;
      template = template.replace('{characterCount}', this.characterCount);
      template = template.replace('{charactersLeft}', this.charactersLeft);
      template = template.replace('{maxLength}', this.maxlength);
      return template;
    },
    inputType() {
      if (['dropdown', 'checkbox', 'switch', 'radio'].includes(this.type)) return undefined;
      return this.type;
    },
    computedComponent() {
      if (this.type === 'dropdown') return this.dropdownComponent;
      if (this.type === 'checkbox') return this.checkboxComponent;
      if (this.type === 'switch') return this.switchComponent;
      if (this.type === 'radio') return this.radioComponent;
      return this.inputComponent;
    },
    hasError() {
      return this.error || !!this.errorMessage;
    },
    computedMessage() {
      return this.message || this.errorMessage;
    },
    hasMessage() {
      return !!this.computedMessage;
    },
    /**
     * Get value length
     */
    characterCount() {
      if (typeof this.value === 'string') {
        return this.value.length;
      }
      if (typeof this.value === 'number') {
        return this.value.toString().length;
      }
      return 0;
    },
    charactersLeft() {
      if (this.maxlength) return this.maxlength - this.characterCount;
      return 0;
    },
  },
  data() {
    return {
      inputErrorMessage: null,
      inputError: false,
    };
  },
  methods: {
    resetValidation() {
      this.$emit('update:error', true);
      this.$emit('update:errorMessage', null);
    },
    updateValue(value) {
      this.$emit('update:value', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.g-field {
  display: block;
  width: 100%;

  .message {
    &.before {
      margin-bottom: var(--g-field-message-gap);
    }
    &.after {
      margin-top: var(--g-field-message-gap);
    }
  }
  .helper-text {
    &.before {
      margin-bottom: var(--g-field-helper-gap, var(--g-field-message-gap));
    }
    &.after {
      margin-top: var(--g-field-helper-gap, var(--g-field-message-gap));
    }
  }
}
</style>
