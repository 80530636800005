<template>
  <div class="s3-uploader">
    <div class="files" v-if="files.length">
      <div class="file" v-for="(file, index) in files" :key="file.id || index">
        <img
          v-if="showThumbnail"
          class="file-thumbnail"
          width="100px"
          :src="file.name"
          :alt="file.name || 'File preview.'"
        />
        <div class="file-details">
          <span v-if="showFileName" class="file-name">{{ file.name }}</span>
          <GIcon v-if="file.hasError" class="file-error-icon" icon="gk-icon-x" width="12" height="12" />
          <template v-else-if="file.isPending !== undefined">
            <StyledProgressBar
              v-if="file.progress !== undefined && file.progress < 1"
              class="file-progress"
              :progress="file.progress"
            />
            <GIcon
              v-else-if="file.progress >= 1"
              class="file-uploaded-icon"
              icon="gk-checkmark"
              width="12"
              height="12"
            />
          </template>
        </div>
        <StyledButton
          label="Remove"
          variant="text danger"
          class="file-remove"
          iconRight="gk-icon-x"
          @click="removeFile(file, index, true)"
        />
      </div>
    </div>
    <div v-else class="no-file">{{ noFileText }}</div>
    <label class="file-input" :class="{ disabled }" :for="`file-input-${uploadId}`">
      <StyledButton
        class="file-input-button"
        variant="text"
        iconRight="gk-icon-upload"
        :disabled="disabled"
        :label="fileInputLabel"
        @click.self="$refs['file-input'].click()"
      />
      <div class="helper-text">{{ formatText(fileInputHelperText) }}</div>
      <input
        ref="file-input"
        type="file"
        :accept="allowedFileTypes"
        :id="`file-input-${uploadId}`"
        :multiple="maxNumberOfFiles > 1"
        :disabled="disabled"
        @change="addFiles"
      />
    </label>
  </div>
</template>

<script>
// TODO restrict uploads once max reached. esp when uploading additional
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { ApiService } from '@/services/api.service';
import { GIcon } from '../../index';
import { StyledButton, StyledProgressBar } from '.';

import '@uppy/core/dist/style.css';

export default {
  components: {
    StyledProgressBar,
    StyledButton,
    GIcon,
  },
  name: 'GKS3Uploader',
  props: {
    id: {
      type: String,
      default: null,
    },
    el: {
      default: null,
    },
    nameParser: {
      type: Function,
      default: (name) => {
        if (!name) return null;
        // This ugly line pulls the file name out of the url. probaly could do with regex if I knew how
        let mediaName = name.split('-').slice(5).join('-');
        if (!mediaName) mediaName = 'File';
        return mediaName;
      },
    },
    fileInputLabel: {
      type: String,
      default: 'Upload Image',
    },
    fileInputHelperText: {
      type: String,
      default: '',
    },
    noFileText: {
      type: String,
      default: 'No file uploaded',
    },
    value: {
      type: [String, Array],
      default: null,
    },
    showThumbnail: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: false,
    },
    showFileName: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
    imageWidth: {
      type: Number,
      default: null,
    },
    imageHeight: {
      type: Number,
      default: null,
    },
    allowedFileTypes: {
      type: Array,
      default: null,
    },
    maxFileSize: {
      type: Number,
      default: null,
    },
    maxNumberOfFiles: {
      type: Number,
      default: 1,
    },
    minNumberOfFiles: {
      type: Number,
      default: 1,
    },
    uploadImmediate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uppy: null,
      increment: 0,
      files: [],
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.files = [].concat(newValue);
          this.files = this.files.map((file) => {
            let newFile = file;
            if (file.mediaName) newFile = { ...file, name: this.nameParser(file.mediaName) };
            return newFile;
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    uploadId() {
      return this.id || this._uid;
    },
    disabled() {
      return this.files.length >= this.maxNumberOfFiles;
    },
  },
  created() {
    this.$emit('update:el', this);
    /*
      Common features:
      - Mulitple file upload
      - Cropper
      -

      Assumptions
      - Everytime I click upload image it overrides that last one selected
      - If multiple images are allowed then it will not override but append them

      Questions
      - If there is a max number of files ex 3 and 2 already exist and an a user selects 2 more
        Should it error out saying too many images. Should it allow 1 image to go through and ignore rest?
        If one goes through should it still throw notification? or error? and where?
      - Should we show some sort of indicator to show that a file is uploading?
      - Should we upload files as soon as they are selected? On submit of form? Maybe it depends on situation
        What are the different options?
        - upload as soon as files are added
        - upload on form submit
        - ?

    */

    //  initialize uppy
    this.uppy = new Uppy({
      id: `upload-${this.uploadId}`,
      restrictions: {
        maxFileSize: this.maxFileSize,
        minFileSize: null,
        maxTotalFileSize: null,
        maxNumberOfFiles: this.maxNumberOfFiles,
        minNumberOfFiles: this.minNumberOfFiles,
        allowedFileTypes: this.allowedFileTypes,
        locale: {},
        infoTimeout: 5000,
      },
    });

    /*
      This mess takes existing files and adds them to the uppy instance,
      This allows them to:
        - Be counted towards restrictions, (ex: max number of files)
        - Still show up in dashboard componets
     */
    let counter = 1;
    this.files.forEach((file) => {
      // Create new image and set properties to add file
      const img = new Image();
      img.src = this.imageSrc(file.mediaName);
      const newFile = {
        // add this counter to make filename unique to uppy to "avoid duplicates"
        name: file.name + counter, // file name
        data: img,
        source: 'local',
        isRemote: true,
      };
      counter += 1;

      // Add file to uppy
      const uppyFileId = this.uppy.addFile(newFile);
      const fileFromUppy = this.uppy.getFile(uppyFileId);
      // This mess makes uppy think it already uploaded these files
      // Hope this lasts...
      // https://github.com/transloadit/uppy/issues/1112#issuecomment-789768323
      this.uppy.emit('upload-started', fileFromUppy);
      this.uppy.emit('upload-progress', fileFromUppy, {
        bytesUploaded: file.size,
        bytesTotal: file.size,
      });
      this.uppy.emit('upload-success', fileFromUppy, 'success');
    });
    // console.log(this.uppy.getFiles());
    if (this.files) {
      this.$emit(
        'update:value',
        this.uppy.getFiles().map((file, index) => ({ ...file, ...this.files[index] })),
      );
    }

    // Plug into uppy event listeners
    this.uppy.on('files-added', (files) => {
      this.files = [...this.files, ...files.map((file) => ({ id: file.id, name: file.name, isPending: true }))];
      if (this.$refs['file-input']) {
        this.$refs['file-input'].value = null;
      }
      this.$emit('update:value', this.files);
      if (this.uploadImmediate) this.uploadFiles();
    });
    this.uppy.on('file-removed', (file) => {
      const fileIndex = this.files.findIndex((f) => f.id === file.id);
      this.files.splice(fileIndex, 1);
      this.$emit('update:value', this.files || null);
    });

    this.uppy.on('upload-progress', (file, progress) => {
      const fileIndex = this.files.findIndex((f) => f.id === file.id);
      this.files[fileIndex] = {
        ...this.files[fileIndex],
        progress: progress.bytesUploaded / progress.bytesTotal,
      };
      this.$emit('update:value', this.files);
    });

    this.uppy.on('upload-error', (file) => {
      const fileIndex = this.files.findIndex((f) => f.id === file.id);
      this.files[fileIndex] = {
        ...this.files[fileIndex],
        hasError: true,
      };
      this.$emit('update:value', this.files);
    });

    this.uppy.on('upload-success', (file, response) => {
      const fileIndex = this.files.findIndex((f) => f.id === file.id);
      this.files[fileIndex] = {
        ...this.files[fileIndex],
        isPending: false,
        hasError: false,
        url: response.uploadURL,
      };
      this.$emit('update:value', this.files);
    });

    // Set uppy to use AWS
    this.uppy.use(AwsS3, {
      id: `awsS3-${this.uploadId}`,
      timeout: 300 * 1000, // 5 minutes
      getUploadParameters: async (file) => {
        const { data } = await ApiService.post('/presigned', {
          filename: file.name,
        });
        const presignedFile = this.files.find((f) => f.id === file.id);
        if (presignedFile) {
          presignedFile.mediaName = data.mediaName;
          this.$emit('update:value', this.files);
        }
        return {
          method: 'PUT',
          url: data.url,
        };
      },
    });
  },
  beforeDestroy() {
    this.uppy.close();
  },
  methods: {
    formatText(string) {
      let formattedString = string;
      Object.keys(this.$props).forEach((prop) => {
        formattedString = formattedString.replace(`{{${prop}}}`, this[prop]);
      });
      return formattedString;
    },
    imageSrc(fileName) {
      if (!fileName) return null;
      return `${process.env.VUE_APP_ASSET_URL}/${fileName}`;
    },
    async removeFile(file, index, skipConfirmation) {
      if (!skipConfirmation) {
        if (!(await this.$refs.deleteModal.confirm())) return;
      }
      if (file.id) {
        this.uppy.removeFile(file.id);
      }
    },
    getState() {
      return this.uppy.getState();
    },
    addFiles(event) {
      // This code will hook up our custom file input button to Uppy
      const files = Array.from(event.target.files).map((file) => ({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file,
      }));
      try {
        this.uppy.addFiles(files);
      } catch (err) {
        if (err.isRestriction) {
          console.error('Restriction error:', err);
        } else {
          console.error(err);
        }
      }
    },
    async uploadFiles() {
      return new Promise((resolve, reject) => {
        this.uploadFilesToS3().then((result) => {
          if (result.failed?.length) reject();
          else resolve();
        });
      });
    },

    uploadFilesToS3() {
      if (this.uppy.getState().error) return this.uppy.retryAll();
      return this.uppy.upload();
    },
  },
};
</script>

<style lang="scss" scoped>
.s3-uploader {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 100%;
  @include bp-lg-laptop {
    flex-direction: row;
  }
  .files,
  .no-file {
    width: 100%;
    margin-right: 35px;
    @include bp-md-tablet {
      width: 50%;
    }
  }
  .no-file {
    opacity: 0.5;
  }
  .file {
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &-details {
      display: flex;
      align-items: center;
      overflow: hidden;
      @include bp-md-tablet {
        max-width: 400px;
      }
    }
    &-uploaded-icon,
    &-error-icon {
      flex-shrink: 0;
      margin: 5px;
    }
    &-error-icon {
      color: $gk-red;
    }
    &-name {
      padding: 2px 0;
      margin-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.75;
    }
    &-remove {
      display: flex;
      align-items: center;
      padding: 2px;
      margin-left: auto;
      @include bp-md-tablet {
        margin-left: initial;
      }
      ::v-deep {
        .icon {
          width: 16px;
          height: 16px;
          margin-left: 12px;
        }
      }
    }
    &-input {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      margin-bottom: 10px;
      @include bp-lg-laptop {
        min-width: 400px;
        margin-top: -38px;
      }

      &:not(.disabled) {
        cursor: pointer;
      }
      .disabled {
        opacity: 0.75;
      }

      .helper-text {
        opacity: 0.5;
        @include font-child;
      }

      &-button {
        padding-left: 0;
        &:disabled {
          opacity: 0.5;
        }
        ::v-deep {
          .icon {
            width: 22px;
            height: 18px;
            margin-left: 15px;
          }
        }
      }
    }
  }
  input[type='file'] {
    display: none;
  }
}
</style>
