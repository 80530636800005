<template>
  <ul :class="containerClass" class="g-pagination pagination_base">
    <li
      v-if="showFirstLastButtons"
      class="pagination_item"
      :class="[pageClass, firstPageSelected ? disabledClass : '']"
    >
      <button
        @click="selectFirstPage"
        class="pagination_button"
        :class="pageLinkClass"
        :tabindex="firstPageSelected ? -1 : 0"
        :disabled="disabled || firstPageSelected"
      >
        <slot name="firstButton"> <GIcon icon="g-arrow" /> </slot>
      </button>
    </li>

    <li
      v-if="!hidePrevNextButtons"
      class="pagination_item"
      :class="[prevClass, firstPageSelected ? disabledClass : '']"
    >
      <button
        @click="prevPage"
        class="pagination_button"
        :class="prevButtonClass"
        :tabindex="firstPageSelected ? -1 : 0"
        :disabled="disabled || firstPageSelected"
      >
        <slot name="prevButton">
          <GIcon icon="g-arrow" />
        </slot>
      </button>
    </li>
    <li
      v-for="(page, index) in pages"
      :key="'page' + index"
      class="pagination_item"
      :class="[
        pageClass,
        page.selected ? activeClass : '',
        page.disabled ? disabledClass : '',
        page.breakView ? breakViewClass : '',
      ]"
    >
      <span v-if="page.breakView" class="pagination_break" :class="[pageLinkClass, breakViewLinkClass]">
        <slot name="breakViewContent">{{ breakViewText }}</slot>
      </span>
      <button
        v-else
        class="pagination_button"
        @click="handlePageSelected(page.index + 1)"
        :class="pageLinkClass"
        :disabled="disabled || page.disabled"
      >
        {{ page.content }}
      </button>
    </li>

    <li v-if="!hidePrevNextButtons" class="pagination_item" :class="[nextClass, lastPageSelected ? disabledClass : '']">
      <button
        @click="nextPage"
        class="pagination_button"
        :class="nextButtonClass"
        :tabindex="lastPageSelected ? -1 : 0"
        :disabled="disabled || lastPageSelected"
      >
        <slot name="nextButton">
          <GIcon icon="g-arrow" :rotate="90" />
        </slot>
      </button>
    </li>

    <li v-if="showFirstLastButtons" class="pagination_item" :class="[pageClass, lastPageSelected ? disabledClass : '']">
      <button
        @click="selectLastPage"
        class="pagination_button"
        :class="pageLinkClass"
        :tabindex="lastPageSelected ? -1 : 0"
        :disabled="disabled || lastPageSelected"
      >
        <slot name="lastButton">
          {{ lastButtonText }}
        </slot>
      </button>
    </li>
  </ul>
</template>

<script>
// TODO verify that the 2 way data binding is working
// TODO should allow for there to be a number larger than the page count
import { GIcon } from '..';

/**
 * @version 0.0.1
 */
export default {
  name: 'GPagination',
  components: { GIcon },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 1,
    },
    forcePage: {
      type: Number,
    },
    clickHandler: {
      type: Function,
      default: () => {},
    },
    pageRange: {
      type: Number,
      default: 3,
    },
    marginPages: {
      type: Number,
      default: 1,
    },
    prevButtonText: {
      type: String,
      default: 'Prev',
    },
    nextButtonText: {
      type: String,
      default: 'Next',
    },
    breakViewText: {
      type: String,
      default: '…',
    },
    containerClass: {
      type: String,
    },
    pageClass: {
      type: String,
    },
    pageLinkClass: {
      type: String,
    },
    prevClass: {
      type: String,
      default: 'li-prev',
    },
    prevButtonClass: {
      type: String,
      default: 'prev',
    },
    nextClass: {
      type: String,
      default: 'li-next',
    },
    nextButtonClass: {
      type: String,
      default: 'next',
    },
    breakViewClass: {
      type: String,
    },
    breakViewLinkClass: {
      type: String,
    },
    activeClass: {
      type: String,
      default: 'active',
    },
    disabledClass: {
      type: String,
      default: 'disabled',
    },
    showFirstLastButtons: {
      type: Boolean,
      default: false,
    },
    firstButtonText: {
      type: String,
      default: 'First',
    },
    lastButtonText: {
      type: String,
      default: 'Last',
    },
    hidePrevNextButtons: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value || this.innerValue;
      },
      set(newValue) {
        this.innerValue = newValue;
      },
    },
    firstPageSelected() {
      return this.selected <= 1;
    },
    lastPageSelected() {
      return this.selected >= this.pageCount || this.pageCount === 0;
    },
    pages() {
      const items = {};
      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index += 1) {
          const page = {
            index,
            content: index + 1,
            selected: index === this.selected - 1,
          };
          items[index] = page;
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2);

        const setPageItem = (index) => {
          const page = {
            index,
            content: index + 1,
            selected: index === this.selected - 1,
          };

          items[index] = page;
        };

        const setBreakView = (index) => {
          const breakView = {
            disabled: true,
            breakView: true,
          };

          items[index] = breakView;
        };

        // 1st - loop thru low end of margin pages
        for (let i = 0; i < this.marginPages; i += 1) {
          setPageItem(i);
        }

        // 2nd - loop thru selected range
        let selectedRangeLow = 0;
        if (this.selected - halfPageRange > 0) {
          selectedRangeLow = this.selected - 1 - halfPageRange;
        }

        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1;
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
        }

        for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i += 1) {
          setPageItem(i);
        }

        // Check if there is breakView in the left of selected range
        if (selectedRangeLow > this.marginPages) {
          setBreakView(selectedRangeLow - 1);
        }

        // Check if there is breakView in the right of selected range
        if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
          setBreakView(selectedRangeHigh + 1);
        }

        // 3rd - loop thru high end of margin pages
        for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i -= 1) {
          setPageItem(i);
        }
      }
      return items;
    },

    pageCount() {
      return Math.ceil(this.totalItems / this.perPage);
    },
  },
  data() {
    return {
      innerValue: 1,
    };
  },
  beforeUpdate() {
    if (this.forcePage === undefined) return;
    if (this.forcePage !== this.selected) {
      this.selected = this.forcePage;
    }
  },
  methods: {
    /**
     * Go to any page
     *
     * @param {number} selected
     * @public
     */
    handlePageSelected(selected) {
      if (this.selected === selected) return;

      this.innerValue = selected;
      this.$emit('input', selected);
      this.clickHandler(selected);
    },
    /**
     * Go to prev page if possible
     *
     * @public
     */
    prevPage() {
      if (this.selected <= 1) return;

      this.handlePageSelected(this.selected - 1);
    },
    /**
     * Go to next page if possible
     *
     * @public
     */
    nextPage() {
      if (this.selected >= this.pageCount) return;

      this.handlePageSelected(this.selected + 1);
    },
    /**
     * Select First Page
     *
     * @public
     */
    selectFirstPage() {
      if (this.selected <= 1) return;

      this.handlePageSelected(1);
    },
    /**
     * Select Last Page
     *
     * @public
     */
    selectLastPage() {
      if (this.selected >= this.pageCount) return;

      this.handlePageSelected(this.pageCount);
    },
  },
};
</script>

<style lang="scss">
.g-pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  list-style: none;

  .pagination_item {
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      .pagination_button {
        font-weight: bold;
      }
    }
    &.li-prev {
      margin-right: auto;
    }
    &.li-next {
      margin-left: auto;
    }
  }
  .pagination_button {
    display: flex;
    align-items: center;
    color: inherit;
    cursor: pointer;
    &:disabled {
      opacity: 0.25;
    }
  }
}
</style>
