import Vue from 'vue';
import packageJSON from '../../package.json';

// Execute Datadog RUM on QA environment only.
// Documentation: https://docs.datadoghq.com/real_user_monitoring/browser/
if (
  process.env.VUE_APP_DATADOG_RUM_APP_ID &&
  process.env.VUE_APP_DATADOG_RUM_CLIENT_TOKEN &&
  process.env.VUE_APP_GITHUB_REPOSITORY &&
  process.env.VUE_APP_DEPLOY_ENV === 'qa'
) {
  const { datadogRum } = require('@datadog/browser-rum');
  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.VUE_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: process.env.VUE_APP_GITHUB_REPOSITORY,
    env: process.env.VUE_APP_DEPLOY_ENV,
    version: packageJSON.version,
    sampleRate: 100, // !! REDUCE IF USING ON PRODUCTION !!
    trackInteractions: true,
    useSecureSessionCookie: true,
  });
  Vue.prototype.$datadogRum = datadogRum;
}
