<template>
  <div class="g-input" :class="[variant, `type--${type}`, { hasIcon: passwordReveal || clearable, error: hasError }]">
    <slot name="iconLeft">
      <GIcon v-if="iconLeft" class="icon icon-left" :icon="iconLeft" v-bind="iconProps" />
    </slot>
    <template v-if="type !== 'textarea' && type !== 'htmlTextarea'">
      <input
        ref="input"
        class="input-element"
        :value="localValue"
        v-bind="{ ...$attrs }"
        :autocomplete="autocomplete"
        :type="newType"
        :required="required"
        v-on="listeners"
        @blur="$emit('validate', localValue)"
      />
      <GButton
        v-if="passwordReveal && type === 'password'"
        class="password-reveal-button input-action icon icon-right"
        :icon="isPasswordVisible ? 'g-eye-off' : 'g-eye'"
        variant="text"
        tabindex="-1"
        @click="togglePasswordVisibility"
      />
      <GButton
        v-if="clearable && localValue"
        class="clear-button input-action icon icon-right"
        variant="text"
        :icon="clearIcon"
        @click="clearInput"
      />
      <slot name="iconRight">
        <GIcon v-if="iconRight" class="icon icon-right" :icon="iconRight" v-bind="iconProps" />
      </slot>
    </template>
    <wysiwyg
      v-else-if="type === 'htmlTextarea'"
      id="htmlTextarea"
      ref="htmlTextarea"
      class="input-element"
      :autocomplete="autocomplete"
      :value="localValue"
      v-bind="{ ...$attrs }"
      :rows="autoResize ? 1 : null"
      :class="[{ autoResize }]"
      v-on="listeners"
      @blur="$emit('validate', localValue)"
      v-model="localValue"
    >
    </wysiwyg>
    <textarea
      v-else
      ref="textarea"
      class="input-element"
      :autocomplete="autocomplete"
      :value="localValue"
      v-bind="{ ...$attrs }"
      :rows="autoResize ? 1 : null"
      :class="[{ autoResize }]"
      v-on="listeners"
      @blur="$emit('validate', localValue)"
    />
  </div>
</template>

<script>
/**
 * This will handle most standard inputs. Its mostly just a wrapper for styling, + a few other baked in features. For now radio and checkboxes will live in their own components<br>
 * visit <a href="#gradio">For radio buttons</a><br>
 * visit <a href="#gcheckbox">For checkboxes</a>
 * @version 0.1.1
 */

import { GButton, GIcon } from '..';
import formMixin from '../mixins/formMixin';

export default {
  name: 'GInput',
  inheritAttrs: false,
  components: {
    GIcon,
    GButton,
  },
  mixins: [formMixin],
  props: {
    /** @model */
    value: [Number, String],
    /**
     * @values Any native input type, and textarea
     */
    type: {
      type: String,
      default: 'text',
    },
    debounce: {
      type: Number,
      default: 0,
    },
    validationDebounce: {
      type: Number,
      default: 0,
    },
    variant: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    iconProps: {
      type: Object,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    /**
     * Adds the reveal password functionality
     */
    passwordReveal: {
      type: Boolean,
    },
    /** Add a button/icon to clear the inputed text */
    clearable: {
      type: Boolean,
      default: false,
    },
    clearIcon: {
      type: String,
      default: 'g-close-x',
    },
    // TODO decide if content editable would be a better use for something like this
    /** setting this on a text area will allow it to auto resize as you type */
    autoResize: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    required: Boolean,
    iconLeft: {
      type: String,
      default: null,
    },
    validationBehavior: {
      type: String,
      default: 'debounce',
    },
    iconRight: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localValue: this.value,
      newType: this.type,
      isPasswordVisible: false,
      debounceTimeout: null,
      validationDebounceTimeout: null,
    };
  },
  computed: {
    // This is going to be a problem in vue 3. Perhaps I can just compute attrs and do like attrs.onInput = (event) => this.updateValue;
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.updateValue(event.target.value);
          this.$emit('updated', event);
        },
      };
    },
    elementRefName() {
      return this.type === 'textarea' ? 'textarea' : 'input';
    },
    elementRef() {
      return this.$refs[this.elementRefName];
    },
    hasError() {
      if (this.error) return true;
      return this.error || this.fieldError.value;
    },
  },
  watch: {
    /**
     * When v-model is changed:
     *   1. Set internal value.
     */
    value: {
      immediate: true,
      handler(value) {
        this.localValue = value;
      },
    },
    localValue: {
      immediate: true,
      handler(value) {
        if (this.type === 'htmlTextarea' && this.$refs.htmlTextarea) {
          // console.log(value);
          this.updateValue(value);
        }
      },
    },
  },
  methods: {
    updateValue(value) {
      let newValue = value;
      if (this.type === 'number') {
        newValue = Number(newValue);
      }
      this.$emit('resetValidation');
      this.localValue = newValue;
      this.resize();
      if (!this.localValue) this.$emit('clear');

      // Don't update v-model until after debounce
      // I think other values should keep updating?

      clearTimeout(this.debounceTimeout);
      if (this.debounce) {
        this.debounceTimeout = setTimeout(() => {
          this.$emit('input', value);
          this.$emit('update:value', value);
        }, this.debounce);
      } else {
        this.$emit('input', value);
        this.$emit('update:value', value);
      }
      clearTimeout(this.validationDebounceTimeout);
      if (this.validationDebounce || this.debounce) {
        this.validationDebounceTimeout = setTimeout(() => {
          this.$emit('validate', value);
        }, this.validationDebounce || this.debounce);
      } else {
        this.$emit('validate', value);
      }
    },
    /**
     * Toggle the visibility of a password-reveal input
     * by changing the type and focus the input right away.
     */
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
      this.newType = this.isPasswordVisible ? 'text' : 'password';

      this.$nextTick(() => {
        this.focus();
      });
    },
    clearInput() {
      this.updateValue('');
      this.focus();
    },
    focus() {
      this.elementRef.focus();
    },
    resize() {
      if (!this.autoResize) return;
      const el = this.elementRef;
      if (el) {
        const style = window.getComputedStyle(el);
        el.style.height = 'auto';
        el.style.overflow = 'hidden';
        el.style.height = `${el.scrollHeight}px`;
        if (parseFloat(el.style.height) >= parseFloat(style.maxHeight)) {
          el.style.overflowY = 'auto';
          el.style.height = style.maxHeight;
          el.scrollTop = el.scrollHeight;
        }
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.debounceTimeout);
    clearTimeout(this.validationDebounceTimeout);
  },
  mounted() {
    if (this.autoResize && this.type === 'textarea') {
      this.resize();
    }
    if (this.autoFocus) {
      this.focus();
    }
  },
};
</script>

<style lang="scss">
@import '~vue-wysiwyg/dist/vueWysiwyg.css';
.g-input {
  --g-input-action-icon-size: 1.25em;
  --g-input-clear-icon-size: var(--g-input-action-icon-size);
  --g-input-password-reveal-icon-size: var(--g-input-action-icon-size);

  position: relative;
  display: flex;
  align-items: center;
  color: var(--g-input-font-color, black);
  background-color: var(--g-input-background-color, white);
  border: 1px solid var(--g-input-border-color, black);

  &:disabled {
    opacity: 0.5;
  }

  &:focus-within {
    --g-input-border-color: orange;
  }

  .input-action {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: var(--g-input-action-icon-size, 1.25em);

    &.clear-button {
      font-size: var(--g-input-clear-icon-size, inherit);
    }
    &.password-reveal-button {
      font-size: var(--g-input-password-reveal-icon-size, inherit);
    }
  }
  .icon {
    flex-shrink: 0;
    color: var(--g-input-icon-color, inherit);

    &-left {
      color: var(--g-input-icon-left-color, inherit);
    }
    &-right {
      color: var(--g-input-icon-right-color, inherit);
    }
  }
  .input-element {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: inherit;
    color: inherit;
    background-color: inherit;
    border: none;
    border-radius: inherit;
    outline: none;
    // TODO Figure out solution for autofill styling on login page
    // Chrome webkit autofill style touch up
    // &:-webkit-autofill,
    // &:-webkit-autofill:hover,
    // &:-webkit-autofill:focus {
    //   border: none;
    //   border-bottom: 1px solid $input-border-light;
    //   -webkit-box-shadow: none;
    //   transition: background-color 5000s ease-in-out 0s;
    //   -webkit-text-fill-color: $text-color-dark;
    // }
    &:not([type='submit'], [type='range']) {
      appearance: none;
    }
    &::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
  &.type--textarea {
    flex-direction: column;
  }
  textarea {
    &.autoResize {
      resize: none;
    }
  }
  input:-webkit-autofill::first-line {
    font-family: inherit;
  }
}
</style>
