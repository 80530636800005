<template>
  <component :is="'GTable'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="gk-table">
    <template v-slot:sortIcon><div></div></template>
    <template v-slot:head v-if="isLoading">
      <Skeleton class="skeleton-header" height="40px" />
    </template>
    <template v-slot:head v-else-if="!isLoading && !data.length"
      ><div class="g-table__header no-results-header"></div
    ></template>
    <template v-slot:body v-if="isLoading">
      <Skeleton
        v-for="loading in perPage"
        :key="loading.id"
        class="skeleton-row"
        height="40px"
        style="margin: 10px 0"
      />
    </template>
    <template v-slot:body v-else-if="!isLoading && !data.length"><div class="no-results">No results</div> </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GTable } from '@grimoire/base';
import { StyledCheckbox } from '@/theme';
import Skeleton from '@/components/Skeleton.vue';

export default {
  name: 'GKTable',
  extends: GTable,
  inheritAttrs: false,
  components: {
    GTable,
    Skeleton,
  },
  props: {
    checkboxComponent: {
      default: () => StyledCheckbox,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
};
</script>

<style lang="scss" scoped>
.gk-table {
  --g-table-row-selector-width: 20px;
  border-collapse: collapse;
  .no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    border: 1px solid $gk-grey-3;
  }
  ::v-deep {
    .g-checkbox {
      .icon-checkmark {
        margin: 4px;
      }
    }
    .g-table {
      &__cell {
        padding: 15px;
        border: 1px solid;
        border-color: $gk-grey-3;
        border-collapse: collapse;
      }
      thead {
        border: none;
        background-color: $gk-dark-blue;
      }
      &__header {
        padding: 15px;
        color: white;
        text-align: left;
        text-transform: capitalize;
        vertical-align: middle;
        background-color: $gk-dark-blue;
        border: 1px solid;
        border-color: $gk-dark-blue;
        font-weight: normal;

        &.no-results-header {
          height: 40px;
        }

        .icon {
          width: 16px;
          &.after {
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>
