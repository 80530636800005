<template>
  <tr class="v-tr" :class="[rowClass, { 'selectable-row': selectable }]" @click="handleRowSelected">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: 'v-tr',
  inheritAttrs: false,
  props: {
    row: null,
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['store'],
  data() {
    return {
      state: this.store._data,
    };
  },
  mounted() {
    if (this.selectable && !this.row) {
      console.error('Row is required if selectable is set to true');
    }
  },
  beforeDestroy() {
    if (!this.state.customSelection) {
      this.$el.removeEventListener('click', this.handleRowSelected);
    }
  },
  computed: {
    isSelected() {
      return this.state.selectedRows.find((it) => it === this.row);
    },
    rowClass() {
      return this.isSelected ? this.state.selectedClass : '';
    },
  },
  methods: {
    handleRowSelected(event) {
      if (!this.selectable) return;
      if (this.store.customSelection) return;

      const source = event.target || event.srcElement;
      if (source.tagName.toLowerCase() === 'td') {
        if (this.isSelected) {
          this.store.deselectRow(this.row);
        } else {
          this.store.selectRow(this.row);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tr {
  white-space: nowrap;
  &.selectable-row {
    cursor: pointer;
  }
}
</style>
