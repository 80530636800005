<template>
  <component
    :is="'GDropdown'"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    class="gk-dropdown"
    @tag="updateOptions"
    @input="validate"
    ref="GKDropdown"
  >
    <template v-slot:tag="{ option, remove }">
      <StyledTag :label="option" @remove="remove(option)" />
    </template>
    <template v-slot:caret="{ toggle }"
      ><GIcon class="multiselect__select" icon="gk-icon-dropdown-arrow" @click="toggle"
    /></template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GDropdown, GIcon } from '@grimoire/base';
import { StyledTag } from '.';

export default {
  name: 'GKDropdown',
  inheritAttrs: false,
  extends: GDropdown,
  components: {
    GDropdown,
    StyledTag,
    GIcon,
  },
  props: {
    searchable: {
      default: false,
    },
  },
  computed: {},
  mounted() {},
  methods: {
    validate(event) {
      this.$nextTick(() => {
        this.$emit('validate', event);
      });
    },
    updateOptions(newTag) {
      this.$emit('update:options', [...this.options, newTag]);
      const value = this.value || [];
      this.$emit('update:value', [...value, newTag]);
    },
  },
};
</script>

<style lang="scss" scoped>
.gk-dropdown {
  font-size: 13px;
  &.error {
    ::v-deep {
      .multiselect {
        &__tags {
          border-color: $gk-red;
        }
      }
    }
  }
  ::v-deep {
    .styled-tag {
      margin: 2.5px;
    }
    .multiselect {
      box-sizing: border-box;
      &__placeholder {
        padding: 0;
        padding-left: 5px;
        margin: 0;
        line-height: 38px;
        color: #adadad;
      }
      &__input {
        @include font-adult;

        padding-right: 30px;
        margin-bottom: 0;
        line-height: 38px;
        background-color: transparent;
        &::placeholder {
          color: #adadad;
        }
      }
      &__single {
        padding: 0;
        padding-left: 5px;
        margin: 0;
        font-size: inherit;
        line-height: 38px;
        background-color: transparent;
      }
      &__select {
        top: 0;
        right: 0;
        box-sizing: border-box;
        width: 20px;
        height: 100%;
        padding: 0;
        margin-right: 10px;
        color: $gk-grey;
      }
      &__tags {
        min-height: 50px;
        padding: 5px;
        border: 1px solid #cbcbcb;
        border-radius: 0;
      }
      &__content-wrapper {
        border-color: #cbcbcb;
      }
      &__option {
        &--highlight {
          background: $gk-dark-blue;
        }
        &--selected {
          &.multiselect__option--highlight {
            // background: $gk-red;
          }
        }
      }
    }
  }
}
</style>
