import Loading from 'vue-loading-overlay';
import VueMq from 'vue-mq';
import Notifications from 'vue-notification';
import vClickOutside from 'v-click-outside';
import { Table, TableColumn, DatePicker } from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import wysiwyg from 'vue-wysiwyg';
import { ApiService } from '@/services/api.service';
import App from './App.vue';
import router from './router';
import store from './store';
import './services/datadog.rum'; // Remove to disable Datadog RUM completely.
// import '@/components/local_modules/assets/g.iconify-bundle';
import '@/assets/icons/gk.iconify-bundle';

// eslint-disable-next-line no-console
Vue.prototype.$log = console.log;
Vue.prototype.$bus = new Vue();

Vue.use(VueClipboard);

Vue.config.productionTip = false;

Vue.use(Loading, {
  color: '#000',
});

Vue.use(Notifications, {
  componentName: 'VueFlyout',
});

// Global registration of loading component
Vue.component('Loading', {
  extends: Loading,
  props: {
    isFullPage: () => false,
  },
});

Vue.use(vClickOutside);

Vue.use(VueMq, {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
    huge: Infinity,
  },
  defaultBreakpoint: 'sm',
});

locale.use(lang);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(wysiwyg, {
  hideModules: {
    justifyLeft: true,
    justifyCenter: true,
    justifyRight: true,
    headings: true,
    link: true,
    table: true,
    removeFormat: true,
    code: true,
    image: true,
    separator: true,
  },
});

// Addtional mixin to vue-mq to check based on a modifier ex. 'md+ or md-'
// Pass in query with modifier or not. Method will return true or false
// Use like v-if="$mqQuery('md+')" and it will check from md to end of array
Vue.mixin({
  methods: {
    $mqQuery(query) {
      if (this.$mqAvailableBreakpoints && this.$mq) {
        // If query equals $mq return true and be done;
        if (query === this.$mq) {
          return true;
        }
        const breakpoints = this.$mqAvailableBreakpoints;
        const breakpointNames = Object.keys(breakpoints);

        // end of the query modifier
        const modifier = query.slice(-1);
        if (modifier === '+' || modifier === '-') {
          const bp = query.slice(0, -1);
          const bpIndex = breakpointNames.indexOf(bp);

          if (bpIndex > -1) {
            const bpArray = [];
            if (modifier === '+') {
              // Add it and all querys following it to an array
              for (let i = bpIndex; i < breakpointNames.length; i += 1) {
                bpArray.push(breakpointNames[i]);
              }
            } else if (modifier === '-') {
              // Add it and all querys preceding it to an array
              for (let i = bpIndex; i >= 0; i -= 1) {
                bpArray.push(breakpointNames[i]);
              }
            }
            // Check that current $mq is included in array
            return bpArray.includes(this.$mq);
          }
        }
      }
      return false;
    },
  },
});

// I would like to turn this into a plugin or something maybe?
ApiService.on(401, () => store.dispatch('logout'));
router.beforeEach((to, from, next) => {
  // To help with occasional bugs adding in way to logout from any route by adding ?logout=true to the end of the url
  if (to.query?.logout) {
    store.dispatch('logout');
    return;
  }
  const loggedIn = store.getters.isLoggedIn;
  if (loggedIn) {
    if (to.path === '/login') {
      next({
        path: '/',
        replace: true,
      });
    } else {
      next();
    }
  } else if (!to.meta?.bypassAuth) {
    // If we are !loggedIn and we can't bypass auth then go to login
    next({
      path: '/login',
      replace: true,
      query: {
        redirect: to.path,
      },
    });
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
