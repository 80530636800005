import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import user from '@/store/modules/user';
import { ApiService } from '@/services/api.service';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['user'],
      rehydrated: (state) => {
        ApiService.setAuth(state.getters.userToken);
      },
    }),
  ],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
  },
});
