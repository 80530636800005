import Vue from 'vue';
import VueRouter from 'vue-router';
// import { showcaseRoutes } from '@/theme';
export const showcaseRoutes = {
  path: '/showcase',
  component: () => import(/* webpackChunkName: "ShowcaseView" */ '@/views/showcase/ShowcaseView.vue'),
  children: [
    {
      path: '',
      name: 'GlobalShowcase',
      component: () => import(/* webpackChunkName: "GlobalShowcase" */ '@/views/showcase/GlobalShowcase.vue'),
    },
    {
      path: 'button',
      name: 'ButtonShowcase',
      component: () => import(/* webpackChunkName: "ButtonShowcase" */ '@/views/showcase/ButtonsShowcase.vue'),
    },
    {
      path: 'checkbox',
      name: 'CheckboxShowcase',
      component: () => import(/* webpackChunkName: "CheckboxShowcase" */ '@/views/showcase/CheckboxShowcase.vue'),
    },
    {
      path: 'input',
      name: 'InputShowcase',
      component: () => import(/* webpackChunkName: "InputShowcase" */ '@/views/showcase/InputShowcase.vue'),
    },
    {
      path: 'datepicker',
      name: 'DatepickerShowcase',
      component: () => import(/* webpackChunkName: "DatepickerShowcase" */ '@/views/showcase/DatepickerShowcase.vue'),
    },
    {
      path: 'switch',
      name: 'SwitchShowcase',
      component: () => import(/* webpackChunkName: "SwitchShowcase" */ '@/views/showcase/SwitchShowcase.vue'),
    },
    {
      path: 'icon',
      name: 'IconShowcase',
      component: () => import(/* webpackChunkName: "IconShowcase" */ '@/views/showcase/IconShowcase.vue'),
    },
    {
      path: 'pagination',
      name: 'PaginationShowcase',
      component: () => import(/* webpackChunkName: "PaginationShowcase" */ '@/views/showcase/PaginationShowcase.vue'),
    },
    {
      path: 'table',
      name: 'TableShowcase',
      component: () => import(/* webpackChunkName: "TableShowcase" */ '@/views/showcase/TableShowcase.vue'),
    },
    {
      path: 'form',
      name: 'FormShowcase',
      component: () => import(/* webpackChunkName: "FormShowcase" */ '@/views/showcase/FormShowcase.vue'),
    },
  ],
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'CMS Login',
    component: () => import(/* webpackChunkName: "CMSLogin" */ '../views/Login.vue'),
    meta: {
      bypassAuth: true,
    },
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "CMSView" */ '../layouts/CMSLayout.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        redirect: 'projects',
      },
      {
        path: 'projects',
        name: 'projects',
        component: () => import(/* webpackChunkName: "ProjectPortfolioTable" */ '../views/ProjectPortfolioTable.vue'),
        meta: {
          routeName: 'Our Work',
        },
      },
      {
        path: 'projects/edit/:id?',
        alias: 'projects/add',
        name: 'projects-form',
        component: () => import(/* webpackChunkName: "ProjectPortfolioForm" */ '../views/ProjectPortfolioForm.vue'),
        meta: {
          routeName: 'Our Work',
        },
      },
      {
        path: 'products',
        name: 'products',
        component: () => import(/* webpackChunkName: "ProductsTable" */ '../views/ProductsTable.vue'),
        meta: {
          routeName: 'Products',
        },
      },
      {
        path: 'products/edit/:id?',
        alias: 'products/add',
        name: 'products-form',
        component: () => import(/* webpackChunkName: "ProductsForm" */ '../views/ProductsForm.vue'),
        meta: {
          routeName: 'Products',
        },
      },
      {
        path: 'news',
        name: 'news',
        component: () => import(/* webpackChunkName: "NewsTable" */ '../views/NewsTable.vue'),
        meta: {
          routeName: 'News',
        },
      },
      {
        path: 'news/edit/:id?',
        alias: 'news/add',
        name: 'news-form',
        component: () => import(/* webpackChunkName: "NewsForm" */ '../views/NewsForm.vue'),
        meta: {
          routeName: 'News',
        },
      },
      {
        path: 'careers',
        name: 'careers',
        component: () => import(/* webpackChunkName: "CareersTable" */ '../views/CareersTable.vue'),
        meta: {
          routeName: 'Careers',
        },
      },
      {
        path: 'careers/edit/:id?',
        alias: 'careers/add',
        name: 'careers-form',
        component: () => import(/* webpackChunkName: "CareerForm" */ '../views/CareersForm.vue'),
        meta: {
          routeName: 'Careers',
        },
      },
    ],
  },
  { ...showcaseRoutes },
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/Error404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    // Scroll to the top of the page on route navigation
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
export { routes };
