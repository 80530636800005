<template>
  <th class="v-th" :class="[sortClass, { sortable: sortEnabled }]" :aria-sort="ariaSortLabel" @click="sort">
    <div class="content">
      <span v-show="!state.hideSortIcons && sortKey" class="icon" :class="sortIconPosition">
        <slot name="descIcon" v-if="order === -1">
          <GIcon :icon="descIcon" rotate="270deg" width="16" height="8" />
        </slot>
        <slot name="sortIcon" v-else-if="order === 0">
          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path
              fill="currentColor"
              d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"
            />
          </svg>
        </slot>
        <slot name="ascIcon" v-else-if="order === 1">
          <GIcon :icon="ascIcon" rotate="90deg" width="16" height="8" />
        </slot>
      </span>
      <slot />
    </div>
  </th>
</template>

<script>
import { uuid } from './table-utils';
import { GIcon } from '..';

export default {
  name: 'v-th',
  components: {
    GIcon,
  },
  props: {
    sortKey: {
      required: false,
      type: [String, Function],
    },
    descIcon: {
      type: String,
      default: 'g-arrow',
    },
    ascIcon: {
      type: String,
      default: 'g-arrow',
    },
    sortIconPosition: {
      type: String,
      default: 'after',
    },
    customSort: {
      required: false,
      type: Function,
    },
    defaultSort: {
      required: false,
      type: String,
      validator: (value) => ['asc', 'desc'].includes(value),
    },
  },
  inject: ['store'],
  data() {
    return {
      id: uuid(),
      order: 0,
      orderClasses: ['vt-desc', 'vt-sortable', 'vt-asc'],
      ariaLabels: ['descending', 'none', 'ascending'],
      state: this.store._data,
    };
  },
  computed: {
    sortEnabled() {
      return this.sortKey || typeof this.customSort === 'function';
    },
    sortId() {
      return this.state.sortId;
    },
    sortClass() {
      return this.state.hideSortIcons ? [this.orderClasses[this.order + 1], 'vt-sort'] : [];
    },
    ariaSortLabel() {
      return this.ariaLabels[this.order + 1];
    },
  },
  watch: {
    sortId(sortId) {
      if (sortId !== this.id && this.order !== 0) {
        this.order = 0;
      }
    },
  },
  mounted() {
    // if (!this.sortKey && !this.customSort) {
    //   throw new Error('Must provide the Sort Key value or a Custom Sort function.');
    // }

    if (this.defaultSort) {
      this.order = this.defaultSort === 'desc' ? -1 : 1;
      this.store.setSort({
        sortOrder: this.order,
        sortKey: this.sortKey,
        customSort: this.customSort,
        sortId: this.id,
      });
      this.$nextTick(() => {
        this.$emit('defaultSort');
      });
    }
  },
  methods: {
    sort() {
      if (this.sortEnabled) {
        this.order = this.order === 0 || this.order === -1 ? this.order + 1 : -1;
        const sort = {
          sortOrder: this.order,
          sortKey: this.sortKey,
          customSort: this.customSort,
          sortId: this.id,
        };
        this.store.setSort(sort);
        this.$emit('sortClicked', sort);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-th {
  white-space: nowrap;
  &.sortable {
    cursor: pointer;
    user-select: none;
  }
  .content {
    display: flex;
    align-items: center;
  }
  .icon {
    display: flex;
    align-items: center;
    &.after {
      order: 1;
      margin-left: 5px;
    }
  }
}
</style>
