<template>
  <div :style="containerStyle" :class="containerClass"></div>
</template>

<script>
export default {
  props: {
    shape: {
      type: String,
      default: 'rectangle',
    },
    size: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '1rem',
    },
    borderRadius: {
      type: String,
      default: null,
    },
    animation: {
      type: String,
      default: 'wave',
    },
  },
  computed: {
    containerClass() {
      return [
        'p-skeleton p-component',
        {
          'p-skeleton-circle': this.shape === 'circle',
          'p-skeleton-none': this.animation === 'none',
        },
      ];
    },
    containerStyle() {
      if (this.size) return { width: this.size, height: this.size, borderRadius: this.borderRadius };
      return { width: this.width, height: this.height, borderRadius: this.borderRadius };
    },
  },
};
</script>

<style>
.p-skeleton {
  position: relative;
  overflow: hidden;
  background-color: #d1d1d1;
  border-radius: 3px;
}
.p-skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  content: '';
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
  transform: translateX(-100%);
  animation: p-skeleton-animation 1.2s infinite;
}
.p-skeleton.p-skeleton-circle {
  border-radius: 50%;
}
.p-skeleton-none::after {
  animation: none;
}
@keyframes p-skeleton-animation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
