<template>
  <label class="g-radio" :class="{ disabled, checked: isChecked, error }" :for="buttonID">
    <input
      v-bind="{ name, disabled, ...$attrs }"
      :checked="isChecked"
      :id="buttonID"
      :value="value"
      type="radio"
      v-on="localListeners"
    />
    <div class="radio-box" aria-hidden="true" focusable="false" :class="{ checked: isChecked }">
      <slot name="radio" v-bind="{ checked: isChecked }">
        <svg class="icon-radio" :class="{ checked: isChecked }" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50%" cy="50%" r="50%" class="outer-circle" />
          <circle v-if="isChecked" cx="50%" cy="50%" r="30%" class="inner-circle" />
        </svg>
      </slot>
    </div>
    <span v-if="hasLabel && !hideLabel" class="label" :class="labelPosition">
      <!-- @slot used for inserting label. Will default to label prop then value if label not provided -->
      <slot v-bind="{ checked: isChecked, label, value }">
        {{ label || value }}
      </slot>
    </span>
  </label>
</template>

<script>
/**
 * @version 0.1.0
 */
export default {
  name: 'GRadio',
  inheritAttrs: false,
  model: {
    prop: 'selectedValue',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    labelPosition: {
      type: String,
      default: 'after',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    selectedValue: {
      type: [String, Boolean, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
  },
  computed: {
    localListeners() {
      return {
        ...this.$listeners,
        change: this.updateRadio,
      };
    },
    isChecked() {
      if (!this.selectedValue && this.checked) return true;
      return this.selectedValue === this.value;
    },
    hasLabel() {
      return !!this.$scopedSlots.default || !!this.label || !!this.value;
    },
    buttonID() {
      return this.id || `g-radio-${this._uid}`;
    },
  },
  methods: {
    updateRadio(event) {
      this.$emit('change', this.value);
      this.$emit('validate', this.value);
      this.$emit('updated', event);
    },
  },
  mounted() {
    if (this.checked) {
      this.updateRadio();
    }
  },
};
</script>

<style lang="scss">
.g-radio {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  &.error {
    .icon-radio {
      border-color: red;
    }
  }

  &,
  * {
    box-sizing: border-box;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  .label {
    &.after {
      margin-left: 5px;
    }
    &.before {
      order: -1;
      margin-right: 5px;
    }
  }
  $box-size: 24px;
  .icon-radio {
    display: block;
    width: $box-size;
    height: $box-size;
    border: 1px solid #555;
    border-radius: 50%;
    .outer-circle {
      fill: transparent;
    }
    &.checked {
      border: none;
      .outer-circle {
        fill: #333;
      }
      .inner-circle {
        fill: white;
      }
    }
  }
  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;

    /* tweak size and position if needed */
    width: $box-size;
    height: $box-size;
    padding: 0;
    margin: 0;
    cursor: inherit;

    /* hide it visually if 0 screen reader will skip allegedly haven't tested */
    opacity: 0.00001;
  }
}
</style>
