<template>
  <component
    :is="componentType"
    v-bind="attributes"
    v-on="$listeners"
    class="g-button"
    :class="[...variants, { rounded }]"
  >
    <!--@slot main button content -->
    <slot name="iconLeft">
      <GIcon v-if="iconLeft" class="icon left" v-bind="{ ...iconProps, ...iconLeftProps }" :icon="iconLeft" />
    </slot>
    <slot>
      <GIcon v-if="icon" class="icon" v-bind="{ ...iconProps, icon }" />
      <template v-else>
        {{ label }}
      </template>
    </slot>
    <slot name="iconRight">
      <GIcon v-if="iconRight" class="icon right" v-bind="{ ...iconProps, ...iconRightProps }" :icon="iconRight" />
    </slot>
  </component>
</template>

<script>
import { GIcon } from '..';
/**
 * @version 0.0.1
 */
export default {
  name: 'GButton',
  inheritAttrs: false,
  components: {
    GIcon,
  },
  props: {
    to: {
      type: String,
      default: undefined,
    },
    href: {
      type: String,
      default: undefined,
    },
    /**
     * space separated list just like you would for 'class'. 'default' and 'primary' will be added if another registered variant is not supplied
     */
    variant: {
      type: String,
      default: 'default primary',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * If a variant is added that is not on this list then 'default' will get added as a class to the button
     */
    typeVariants: {
      type: Array,
      default: () => ['default', 'outline', 'text', 'link', 'unstyled'],
    },
    /**
     * If a variant is added that is not on this list then 'primary' will get added as a class to the button
     */
    colorVariants: {
      type: Array,
      default: () => ['primary', 'secondary', 'accent', 'danger', 'warning', 'success', 'info'],
    },
    icon: {
      type: String,
      default: null,
    },
    iconProps: {
      type: Object,
    },
    iconLeft: {
      type: [String, Object],
      default: null,
    },
    iconLeftProps: {
      type: Object,
    },
    iconRight: {
      type: [String, Object],
      default: null,
    },
    iconRightProps: {
      type: Object,
    },
  },
  computed: {
    // This fixes the issue of router-link losing href while :to is set while href is undefined/null
    // avoids adding props to component if they haven't been defined
    attributes() {
      const optionalProps = ['to', 'href', 'disabled', 'type', 'rel'];
      const filteredAttrs = {};
      // loop through optional props and apply them to attribute list if they are defined
      optionalProps.forEach((prop) => {
        if (this[prop] !== undefined) filteredAttrs[prop] = this[prop];
      });
      return { ...this.$attrs, ...filteredAttrs };
    },
    type() {
      if (this.$attrs.type) return this.$attrs.type;
      if (this.componentType === 'button') return 'button';
      return undefined;
    },
    rel() {
      if (this.$attrs.rel) return this.$attrs.rel;
      if (this.$attrs.target && this.$attrs.target === '_blank') return 'noopener noreferrer';
      return undefined;
    },
    componentType() {
      if (!this.disabled) {
        if (this.to) {
          return 'router-link';
        }
        if (this.href) {
          return 'a';
        }
      }
      return 'button';
    },
    variants() {
      const variants = this.variant.split(' ');
      // If none of the variants applied are in the valid list then add the defaults
      if (!variants.some((variant) => this.typeVariants.includes(variant))) {
        variants.push('default');
      }
      if (!variants.some((variant) => this.colorVariants.includes(variant))) {
        variants.push('primary');
      }
      return variants;
    },
  },
};
</script>

<style lang="scss">
// Standard GButton overrides
.g-button {
  $color-primary: rgb(119, 119, 206);

  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  font-family: inherit;
  font-size: 14px;
  color: inherit;
  text-decoration: none;
  background: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  // decorative styles
  background-color: $color-primary;
  border: 0;
  appearance: none;

  &.unstyled {
    background: none;
    border: none;
  }

  &.rounded {
    border-radius: 9999px;
  }
  &.outline {
    background-color: transparent;
    border: 1px solid;
  }
  &.text {
    background-color: transparent;
    border: none;
  }
  &.link {
    color: inherit;
    background: none;
    border-radius: 0;
    &:not(:disabled) {
      &:hover {
        text-decoration: underline;
      }
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  .icon {
    &.left {
      margin-right: var(--g-button-icon-left-gap, var(--g-button-icon-gap));
    }
    &.right {
      margin-left: var(--g-button-icon-right-gap, var(--g-button-icon-gap));
    }
  }
}
</style>
