<template>
  <component :is="'GField'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="gk-field" ref="compRef">
    <template v-if="type === 'combobox'" v-slot="{ hasError }">
      <StyledChips
        :chips="value"
        v-bind="inputOptions"
        :error="hasError"
        v-on="$listeners"
        @update:chips="$emit('update:value', $event)"
      />
    </template>
    <template v-else-if="type === 'switch'" v-slot="{ hasError }">
      <StyledSwitch
        :checked="value"
        v-bind="inputOptions"
        :error="hasError"
        v-on="$listeners"
        @update:checked="$emit('update:value', $event)"
      />
    </template>
    <template v-else-if="type === 'checkbox'" v-slot="{ hasError }">
      <template v-if="options">
        <StyledCheckbox
          v-for="option in options"
          :key="option"
          :checked="value"
          v-bind="inputOptions"
          :value="option"
          :label="option"
          :error="hasError"
          v-on="$listeners"
          @update:checked="$emit('update:value', $event)"
        />
      </template>
      <StyledCheckbox
        v-else
        v-bind="inputOptions"
        :checked="value"
        :error="hasError"
        v-on="$listeners"
        @update:checked="$emit('update:value', $event)"
      />
    </template>
    <template v-else-if="type === 'date'" v-slot="{ hasError }">
      <StyledDatepicker
        :value="value"
        v-bind="inputOptions"
        :error="hasError"
        v-on="$listeners"
        @input="$emit('update:value', $event)"
      />
    </template>
    <template v-else-if="type === 'file'" v-slot="{ hasError }">
      <StyledS3Uploader :value="value" :el="el" v-bind="inputOptions" :error="hasError" v-on="$listeners" />
    </template>
    <template v-slot:count="{ charactersLeft }">
      {{ charactersLeft }} {{ `character${charactersLeft === 1 ? '' : 's'}` }} remaining
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GField } from '@grimoire/base';
import {
  StyledSwitch,
  StyledInput,
  StyledDropdown,
  StyledChips,
  StyledCheckbox,
  StyledDatepicker,
  StyledS3Uploader,
} from '.';

export default {
  name: 'GKField',
  extends: GField,
  inheritAttrs: false,
  components: {
    GField,
    StyledCheckbox,
    StyledSwitch,
    StyledChips,
    StyledDatepicker,
    StyledS3Uploader,
  },
  props: {
    inputComponent: {
      default: () => StyledInput,
    },
    dropdownComponent: {
      default: () => StyledDropdown,
    },
    options: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.gk-field {
  &.error {
    ::v-deep {
      > .label,
      > .message {
        color: $gk-red;
      }
    }
  }
  ::v-deep {
    --g-field-message-gap: 5px;
    --g-field-helper-gap: 10px;
    > .label {
      display: block;
      @include font-lion;

      margin-bottom: 9px;
    }
    > .message {
      color: rgba($gk-dark-blue, 0.5);
      @include font-child;
    }
    > .helper-text {
      color: rgba($gk-dark-blue, 0.5);
      @include font-child;
    }

    .datepicker-input {
      width: 100%;
    }
  }
}
</style>
