<template>
  <component
    v-if="pageCount > 1"
    :is="'GPagination'"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    class="gk-pagination"
  >
    <template v-slot:prevButton>
      <GIcon icon="gk-icon-arrow-left" class="arrow" />
    </template>
    <template v-slot:nextButton>
      <GIcon icon="gk-icon-arrow-left" class="arrow right" flip="horizontal" />
    </template>

    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GPagination, GIcon } from '@grimoire/base';

export default {
  name: 'GKPagination',
  inheritAttrs: false,
  extends: GPagination,
  components: {
    GPagination,
    GIcon,
  },
};
</script>

<style lang="scss" scoped>
.gk-pagination {
  height: 24px;
  color: $gk-grey;
  @include bp-sm-phone-landscape {
    max-width: 400px;
  }

  ::v-deep {
    .pagination_item {
      height: 100%;

      &.active {
        color: $gk-grey-2;
      }
    }
    .pagination_break,
    .pagination_button {
      height: 100%;
      padding: 0 15px;
      user-select: none;
    }
    .pagination_button {
      background: none;
      border: none;
    }
  }
}
</style>
