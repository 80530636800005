<template>
  <div :class="containerClass" ref="avatar">
    <slot>
      <transition :name="transitionName">
        <!-- imageSrc will go null if image loading fails -->
        <img
          class="image"
          v-if="!!imageSrc"
          :key="imageSrc"
          :src="imageSrc"
          :width="imageWidth"
          :height="imageWidth"
          @error="imageSrc = null"
        />
        <!-- @slot For placeholder if no src or while image loading.
          If slot not provided defaults to trying the following order:
          if label,
          else if name,
          else icon,
        -->
        <slot name="placeholder" v-else-if="!hidePlaceholder">
          <span class="text" v-if="label" key="label">{{ label }}</span>
          <span class="text" v-else-if="name" key="name">{{ initials }}</span>
          <component class="icon" v-else-if="icon" :is="icon" key="icon" />
        </slot>
      </transition>
    </slot>
  </div>
</template>

<script>
// TODO make image loading the same for thumbnail and avatar. use one image component
import UserPlaceholder from './assets/user_silhouette.svg';

export default {
  name: 'GAvatar',
  components: {
    UserPlaceholder,
  },
  props: {
    /** Will show placeholder until image is loaded */
    label: {
      type: String,
      default: null,
    },
    /** Will parse this for initials based on spaces, shows up as placeholder if no src provided or while loading */
    name: {
      type: String,
      default: null,
    },
    /** shows up as placeholder if no src provided or while loading, has default avatar silhouette */
    icon: {
      type: Object,
      default: () => UserPlaceholder,
    },
    src: {
      type: String,
      default: null,
    },
    /**
     * @desc size of avatar, sets a css var named --g-avatar-size, setting through here will make the css var most specific and can't be overwritten in css in style tag
     * @param String || Number
     * if string, checks if it has a key in sizes object, else set value as is
     * if number appends `px` to value
     * */
    size: {
      type: [String, Number],
      default: null,
    },
    /**  An object containing different sizes, use size props to select which one */
    sizes: {
      type: Object,
      default: () => ({
        tiny: '20px',
        sm: '30px',
        md: '40px',
        lg: '60px',
        xl: '100px',
        xxl: '200px',
      }),
    },
    /**
     * @desc set transition name
     * @default fade
     * @param String, will need to use proper vue transition css naming to set transition
     *  */
    transitionName: {
      type: String,
      default: 'fade',
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageLoading: false,
      imageFailed: false,
      imageSrc: null,
      imageWidth: null,
      sizeClass: null,
    };
  },
  watch: {
    sizes() {
      this.setSize();
    },
    size() {
      this.setSize();
    },
    src() {
      this.loadImageSrc();
    },
  },
  computed: {
    defaultSizes() {
      return this.$options.props.sizes.default();
    },
    containerClass() {
      return [
        'g-avatar',
        {
          circle: this.round,
        },
        this.sizeClass ? `size--${this.sizeClass}` : null,
      ];
    },
    iconClass() {
      return ['icon', this.icon];
    },
    initials() {
      if (!this.name) return null;
      const parsedName = this.name.split(' ');
      let chars = '';
      for (let i = 0; i < parsedName.length; i += 1) {
        chars += parsedName[i][0];
      }
      return chars.toUpperCase();
    },
  },
  mounted() {
    if (this.src) this.loadImageSrc();
    this.setSize();
  },
  methods: {
    loadImageSrc() {
      this.imageLoading = true;
      const img = new Image();
      const _this = this;
      function onLoad() {
        _this.imageLoading = false;
        _this.imageFailed = false;
        _this.imageSrc = this.src;
        img.removeEventListener('load', this.onLoad);
      }
      function onError() {
        _this.imageLoading = false;
        _this.imageFailed = true;
        _this.imageSrc = null;
        img.removeEventListener('error', this.onError);
      }
      img.addEventListener('load', onLoad);
      img.addEventListener('error', onError);
      img.src = this.src;
    },
    setSize() {
      this.sizeClass = null;
      this.imageWidth = null;
      if (this.$refs.avatar && this.size) {
        let { size } = this;
        if (typeof this.size === 'number') {
          size = `${this.size}px`;
        } else if (Object.keys(this.sizes).includes(this.size)) {
          size = this.sizes[this.size];
          this.sizeClass = this.size;
        }
        this.imageWidth = size;
        this.$refs.avatar.style.setProperty('--g-avatar-size', size);
      }
    },
  },
};
</script>

<style lang="scss">
.g-avatar {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--g-avatar-size, 4rem);
  height: var(--g-avatar-size, 4rem);
  overflow: hidden;
  font-size: var(--g-avatar-size, 4rem);
  background-color: darkgray;
  &.circle {
    border-radius: 50%;

    .image {
      border-radius: 50%;
    }
  }
  .text {
    font-size: var(--g-avatar_text-size, 0.8em);
    color: var(--g-avatar_text-color, inherit);
    text-align: center;
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  .icon {
    max-width: var(--g-avatar_icon-size, 100%);
    max-height: var(--g-avatar_icon-size, 100%);
    color: var(--g-avatar_icon-color, inherit);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity var(--g-avatar-transition-duration, 0.5s) var(--g-avatar-transition-easing, ease-in-out)
      var(--g-avatar-transition-delay, 0s);
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
