<template>
  <component :is="'GCheckbox'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="gk-checkbox">
    <template v-slot:icon="{ checked }">
      <GIcon v-if="checked" icon="gk-checkmark" class="icon-checkmark" />
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GCheckbox, GIcon } from '@grimoire/base';

export default {
  name: 'GKCheckbox',
  inheritAttrs: false,
  extends: GCheckbox,
  components: {
    GCheckbox,
    GIcon,
  },
  props: {},
};
</script>

<style lang="scss" scoped>
@import './assets/scss/colors';
.gk-checkbox {
  --g-checkbox-size: 30px;
  --g-checkbox-label-gap: 10px;
  &.error {
    ::v-deep {
      .checkbox {
        border: 1px solid $gk-red;
      }
    }
  }
  ::v-deep {
    .checkbox {
      box-sizing: content-box;
      border-color: $gk-grey;
      &.checked {
        background-color: $gk-grey;
      }
    }
    .icon-checkmark {
      margin: 7px;
      color: $gk-white;
    }
  }
}
</style>
