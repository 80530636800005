<template>
  <ul class="g-breadcrumbs">
    <template v-for="(breadcrumb, index) in localBreadcrumbs">
      <li class="breadcrumb" :class="{ current: breadcrumb.current }" :key="`breadcrumb_${index}`">
        <router-link v-if="breadcrumb.to" class="breadcrumb_item" v-bind="breadcrumb">{{
          breadcrumb.name
        }}</router-link>
        <a v-else-if="breadcrumb.href" class="breadcrumb_item" v-bind="breadcrumb">{{ breadcrumb.name }}</a>
        <span class="breadcrumb_item" v-else>{{ breadcrumb.name }}</span>
      </li>
      <span v-if="index < localBreadcrumbs.length - 1" class="separator" :key="`separator_${index}`">
        <slot name="separator">{{ separator }}</slot>
      </span>
    </template>
  </ul>
</template>

<script>
export default {
  props: {
    current: {
      type: [String, Object],
      default: '',
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    separator: {
      type: String,
      default: '/',
    },
  },
  computed: {
    localBreadcrumbs() {
      const breadcrumbs = [...this.breadcrumbs];
      if (this.current) {
        if (typeof this.current === 'object') {
          breadcrumbs.push({ ...this.current, current: true });
        } else {
          breadcrumbs.push({ name: this.current, current: true });
        }
      }
      return breadcrumbs;
    },
  },
};
</script>

<style lang="scss">
.g-breadcrumbs {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  .breadcrumb {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
</style>
