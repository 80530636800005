<template>
  <div class="g-progress-circle">
    <svg class="progress-circle-wrapper" x="0px" y="0px" :viewBox="`0 0 ${viewBoxWidth} ${viewBoxWidth}`" ref="svg">
      <defs>
        <!-- @slot Mostly useful for defining a gradient stroke -->
        <slot name="svgDefs" />
      </defs>
      <circle class="center-circle" :cx="halfOfViewBox" :cy="halfOfViewBox" :r="radius" :stroke-width="strokeWidth" />
      <!-- Added - 1 to hide bleeding edge gap on two circles -->
      <circle
        class="progress-bar"
        :cx="halfOfViewBox"
        :cy="halfOfViewBox"
        :r="radius - 1"
        :stroke-width="strokeWidth"
        :stroke-dasharray="strokeDashArray"
        :stroke-dashoffset="offset"
      />
    </svg>
    <div v-if="$slots.default" class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/**
 * @version 0.0.1
 */

export default {
  name: 'GProgressCircle',
  components: {},
  props: {
    progress: {
      type: Number,
      default: 0,
      validator: (val) => typeof val === 'number' && val >= 0 && val <= 1,
    },
    radius: {
      type: Number,
      default: 200,
    },
    strokeWidth: {
      type: Number,
      default: 43,
    },
  },
  data() {
    return {
      offset: 0,
    };
  },
  computed: {
    halfOfViewBox() {
      return this.viewBoxWidth / 2;
    },
    viewBoxWidth() {
      return this.radius * 2 + this.strokeWidth;
    },
    strokeDashArray() {
      return 2 * this.radius * Math.PI;
    },
  },
  methods: {
    updateDashoffset() {
      this.offset = this.strokeDashArray - this.strokeDashArray * this.progress;
    },
  },
  watch: {
    progress() {
      this.updateDashoffset();
    },
  },
  mounted() {
    // Just to give it a smidge of time to change
    this.offset = this.strokeDashArray;
    setTimeout(() => {
      this.updateDashoffset();
    }, 1);
  },
};
</script>

<style lang="scss">
.g-progress-circle {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  max-width: 200px;
  height: 100%;
  .progress-circle-wrapper {
    display: block;
  }
  .center-circle {
    fill: black;
    stroke: #dfe4e8;
  }
  .progress-bar {
    fill: none;
    stroke: coral;
    transform: rotateZ(-90deg);
    transform-origin: center;
    will-change: stroke-dashoffset;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
