<template>
  <table class="g-table">
    <thead>
      <slot name="head">
        <VTh v-if="selectable" v-bind="thProps" class="g-table__header g-table__header--row-selector">
          <Component :is="checkboxComponent" type="checkbox" v-model="allChecked" />
        </VTh>
        <VTh
          v-for="header in displayHeaders"
          v-bind="{ ...thProps, serverSideSort }"
          v-on="$listeners"
          :key="`${headerKey(header)}`"
          :sortKey="headerSortKey(header) || undefined"
          :defaultSort="header.defaultSort || undefined"
          :customSort="header.customSort || undefined"
          class="g-table__header"
          :class="`g-table__header--${headerKey(header)}`"
        >
          <slot :name="`header-${headerKey(header)}`" v-bind="header">{{ headerLabel(header) }}</slot>
          <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
            <slot :name="name" v-bind="slotData" />
          </template>
        </VTh>
      </slot>
    </thead>
    <slot name="body" :displayData="displayData">
      <tr v-for="row in displayData" v-bind="trProps" :key="`${row.id}`" :row="row">
        <td v-if="selectable" class="g-table__cell g-table__cell--row-selector">
          <Component :is="checkboxComponent" v-model="state.selectedRows" :value="row" hideLabel />
        </td>
        <slot :name="row" v-bind="row">
          <td
            v-for="header in displayHeaders"
            :key="`${headerKey(header)}-${row.id}`"
            class="g-table__cell"
            :class="`g-table__cell--${headerKey(header)}`"
          >
            <slot :name="headerKey(header)" v-bind="{ row, header, value: row[headerKey(header)] }">
              {{ rowValue(row, header, headerKey(header)) }}
            </slot>
          </td>
        </slot>
      </tr>
    </slot>
  </table>
</template>

<script>
import Vue from 'vue';
import { doFilter, doSort, calculateTotalPages, doPaginate } from './table-utils';
import { VTh } from '.';
import { GCheckbox } from '..';
import store from './store';

export default {
  name: 'SmartTable',
  components: {
    VTh,
  },
  props: {
    checkboxComponent: {
      default: () => GCheckbox,
    },
    data: {
      required: true,
      type: Array,
    },
    headers: {
      type: Array,
      default: null,
    },
    filters: {
      required: false,
      type: Object,
    },
    currentPage: {
      required: false,
      type: Number,
    },
    pageSize: {
      required: false,
      type: Number,
    },
    allowSelection: {
      required: false,
      type: Boolean,
      default: false,
    },
    selectionMode: {
      required: false,
      type: String,
      default: 'single',
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectedClass: {
      required: false,
      type: String,
      default: 'vt-selected',
    },
    customSelection: {
      required: false,
      type: Boolean,
    },
    hideSortIcons: {
      required: false,
      type: Boolean,
    },
    thProps: {
      type: Object,
      default: null,
    },
    trProps: {
      type: Object,
      default: null,
    },
    serverSideSort: {
      type: Boolean,
      default: false,
    },
  },
  beforeCreate() {
    this.store = new Vue(store);
  },
  provide() {
    return {
      store: this.store,
    };
  },
  data() {
    return {
      state: this.store._data,
      initialLoad: false,
    };
  },
  computed: {
    allChecked: {
      get() {
        // eslint-disable-next-line eqeqeq
        return this.data.length > 0 && this.data.every((row) => this.selectedRows.includes(row));
      },
      set(checked) {
        if (!checked) {
          this.deselectAll();
        } else {
          this.selectAll([...this.data]);
        }
      },
    },
    needsPaginationReset() {
      return this.currentPage > this.totalPages;
    },
    filteredData() {
      if (this.data.length === 0) {
        return [];
      }

      if (typeof this.filters !== 'object') {
        return this.data;
      }

      return doFilter(this.data, this.filters);
    },
    totalItems() {
      return this.filteredData.length;
    },
    sortedData() {
      if (!this.serverSideSort && (this.state.sortKey || this.state.customSort) && this.state.sortOrder !== 0) {
        return doSort(this.filteredData, this.state.sortKey, this.state.customSort, this.state.sortOrder);
      }

      return this.filteredData;
    },
    totalPages() {
      if (!this.pageSize) return 0;

      return calculateTotalPages(this.totalItems, this.pageSize);
    },
    displayHeaders() {
      if (this.headers) {
        return this.headers.filter((header) => {
          if (!header.hidden) return true;
          if (header.hidden instanceof Function) return !header.hidden();
          return header.hidden;
        });
      }
      if (this.data.length) {
        return Object.keys(this.data[0]);
      }
      return null;
    },
    displayData() {
      if (this.pageSize) {
        return doPaginate(this.sortedData, this.pageSize, this.currentPage);
      }

      return this.sortedData;
    },
    selectedRows() {
      return this.state.selectedRows;
    },
  },
  watch: {
    displayData: {
      handler() {
        if (!this.initialLoad) {
          this.initialLoad = true;
          this.$emit('loaded', this);
        }
      },
      immediate: true,
    },
    selectionMode: {
      handler(mode) {
        this.state.selectionMode = mode;
      },
      immediate: true,
    },
    selectedClass: {
      handler(selectedClass) {
        this.state.selectedClass = selectedClass;
      },
      immediate: true,
    },
    customSelection: {
      handler(customSelection) {
        this.state.customSelection = customSelection;
      },
      immediate: true,
    },
    hideSortIcons: {
      handler(hideSortIcons) {
        this.state.hideSortIcons = hideSortIcons;
      },
      immediate: true,
    },
    needsPaginationReset: {
      handler(needsReset) {
        if (needsReset) {
          this.$emit('update:currentPage', 1);
        }
      },
      immediate: true,
    },
    totalPages: {
      handler(totalPages) {
        this.$emit('totalPagesChanged', totalPages);
      },
      immediate: true,
    },
    totalItems: {
      handler(totalItems) {
        this.$emit('totalItemsChanged', totalItems);
      },
      immediate: true,
    },
    selectedRows: {
      handler(selected) {
        this.$emit('selectionChanged', selected);
        this.$emit('update:selectedRows', selected);
      },
      immediate: true,
    },
  },
  methods: {
    rowValue(row, header, key) {
      if (header.formattedValue) return header.formattedValue(row[key]);
      return row[key];
    },
    headerKey(header) {
      return header.key || header.label || header;
    },
    headerSortKey(header) {
      if (header.sortKey) return header.sortKey;
      return header.sortable ? this.headerKey(header) : undefined;
    },
    headerLabel(header) {
      return header.label || header.key || header;
    },
    revealItem(item) {
      if (!this.pageSize) {
        return true;
      }

      let index;
      if (typeof item === 'function') {
        index = this.sortedData.findIndex(item);
      } else {
        index = this.sortedData.indexOf(item);
      }

      if (index === -1) {
        return false;
      }

      const currentPage = Math.ceil((index + 1) / this.pageSize);
      this.$emit('update:currentPage', currentPage);

      return true;
    },
    revealPage(page) {
      if (!this.pageSize || Number.isNaN(page) || page < 1) {
        return;
      }

      this.$emit('update:currentPage', page);
    },
    selectRow(row) {
      this.store.selectRow(row);
    },
    selectRows(rows) {
      this.store.selectRows(rows);
    },
    deselectRow(row) {
      this.store.deselectRow(row);
    },
    deselectRows(rows) {
      this.store.deselectRows(rows);
    },
    selectAll(rows) {
      // if (this.selectionMode === 'single') return;
      if (rows) {
        this.store.selectAll(rows);
        return;
      }
      this.store.selectAll([...this.data]);
    },
    deselectAll() {
      this.store.deselectAll();
    },
  },
};
</script>

<style lang="scss" scoped>
.g-table {
  --g-table-row-selector-width: 50px;

  border-collapse: collapse;
  // table-layout: fixed;
  &__cell,
  &__header {
    &--row-selector {
      .g-checkbox {
        --g-checkbox-size: var(--g-table-row-selector-width);
      }

      width: var(--g-table-row-selector-width);
    }
  }

  td,
  th {
    padding: 0;
  }
}
</style>
