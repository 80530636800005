<template>
  <component :is="'GSwitch'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-switch">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GSwitch } from '@grimoire/base';

export default {
  name: 'PassportSwitch',
  inheritAttrs: false,
  extends: GSwitch,
  components: {
    GSwitch,
  },
  props: {
    rounded: {
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './assets/scss/colors';
.passport-switch {
  --g-switch-width: 40px;
  --g-switch-height: 18px;
  --g-switch-track-color: #{rgba($gk-grey, 0.3)};
  --g-switch-track-color--checked: #{$gk-grey};
  --g-switch-thumb-color: #{$gk-white};
  --g-switch-thumb-gap: 1px;
  --g-checkbox-size: var(--g-switch-height);
  --g-checkbox-label-gap: 10px;

  &.error {
    ::v-deep {
      .track {
        border: 1px solid $gk-red;
      }
    }
  }
  ::v-deep {
    .track {
      .thumb {
        box-shadow: 0 1px 3px #0000001a;
      }
    }
  }
}
</style>
