<template>
  <div class="gk-tag styled-tag">
    {{ label }}
    <GButton v-if="!cantRemove" variant="text" icon="gk-x-icon" @click="$emit('remove')" />
  </div>
</template>

<script>
import { GButton } from '@grimoire/base';

export default {
  components: {
    GButton,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    cantRemove: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.gk-tag {
  display: inline-flex;
  align-items: center;
  @include font-dog;

  padding: 7px;
  color: $gk-white;
  background-color: $gk-grey-2;
  border-radius: 3px;

  .g-button {
    padding: 2px;
    margin-left: 4px;
    font-weight: bold;
    ::v-deep {
      .icon {
        width: 8px;
        height: 8px;
        stroke-width: 2px;
      }
    }
  }
}
</style>
