<template>
  <component :is="'GBreadcrumbs'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="gk-breadcrumbs font-child">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GBreadcrumbs } from '@grimoire/base';

export default {
  name: 'GKBreadcrumbs',
  inheritAttrs: false,
  extends: GBreadcrumbs,
  components: {
    GBreadcrumbs,
  },
};
</script>

<style lang="scss" scoped>
@import './assets/scss/colors';
.gk-breadcrumbs {
  color: $gk-dark-blue;
  text-transform: uppercase;

  ::v-deep {
    .breadcrumb a {
      color: inherit;
      text-decoration: none;
    }
    .separator {
      margin: 0 4px;
    }
  }
}
</style>
