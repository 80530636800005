<template>
  <div class="g-progress-bar">
    <div class="progress" :style="{ width: `${progress * 100}%` }" />
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.g-progress-bar {
  position: relative;
  width: 100%;
  height: var(--g-progress-bar-height, 10px);
  background-color: var(--g-progress-bar-track-color, lightgray);
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--g-progress-bar-progress-color, rgb(45, 157, 255));
  }
}
</style>
