<template>
  <component
    :is="'GInput'"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    class="gk-input"
    :class="variant"
    ref="compRef"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GInput } from '../..';

export default {
  name: 'GKInput',
  inheritAttrs: false,
  extends: GInput,
  props: {
    variant: {
      type: String,
      default: 'outlined',
      validator: (value) => ['filled', 'outlined', 'lined'].includes(value),
    },
    passwordReveal: {
      default: true,
    },
    clearIcon: {
      default: 'gk-x-icon',
    },
  },
  components: {
    GInput,
  },
  mounted() {
    this.$children.forEach((child) => {
      this.$refs = { ...child.$refs, ...this.$refs };
    });
  },
};
</script>

<style lang="scss" scoped>
@import './assets/scss/colors';
.gk-input {
  --g-input-background-color: transparent;
  --g-input-font-color: #{$gk-dark-blue};
  --g-input-action-icon-size: 24px;
  --g-input-clear-icon-size: 14px;

  padding: 0 8px;
  border-radius: 0;
  ::v-deep {
    .clear-button {
      padding: 5px;
      margin-right: 2px;
    }
    .input-element {
      padding: 13px 8px 14px;
      @include font-adult;

      &::placeholder {
        color: $gk-grey;
        opacity: 1;
      }
    }
  }
  &.filled {
    background-color: $gk-white;

    --g-input-border-color: transparent;
  }
  &.outlined {
    --g-input-border-color: #d1d1d1;
  }
  &.lined {
    border-width: 0;
    border-bottom-width: 1px;

    --g-input-border-color: #8b8d8d;
  }
  &.type--textarea {
    padding: 0;
    border-radius: 3px;
    ::v-deep {
      .input-element {
        min-height: 500px;
        padding: 13px 16px 14px;
        resize: none;
      }
    }
  }
  &:focus-within {
    --g-input-border-color: orange;
  }
  &.error {
    --g-input-border-color: #{$gk-red-2};
  }
}
</style>
