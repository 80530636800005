import { ApiService } from '@/services/api.service';
import router from '@/router';

export default {
  state: {
    userInfo: null,
    userToken: null,
  },

  getters: {
    userInfo: (state) => state.userInfo,
    userToken: (state) => state.userToken,
    isLoggedIn: (state) => !!state.userToken,
  },

  // TODO need to redirect to login on unauthorized I think?
  actions: {
    login: ({ commit }, { username, password }) =>
      new Promise((resolve, reject) => {
        ApiService.post('login', { username, password })
          .then(({ data }) => {
            commit('SET_AUTH', data.token);
            commit('SET_USER', { id: data.id, username: data.username });
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    logout: ({ dispatch }) =>
      new Promise((resolve) => {
        dispatch('clearTokenAndRedirect');
        resolve(true);
      }),
    clearTokenAndRedirect({ commit }, noRedirect) {
      commit('PURGE_AUTH');
      if (!noRedirect) {
        router.push('/login');
      }
    },
  },

  mutations: {
    SET_AUTH(state, token) {
      ApiService.setAuth(token);
      state.userToken = token;
    },
    SET_USER(state, userInfo) {
      state.userInfo = userInfo;
    },
    PURGE_AUTH(state) {
      state.userInfo = null;
      state.userToken = null;
    },
  },
};
