<template>
  <component
    :is="inputComponent"
    class="datepicker-input"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    iconLeft="gk-icon-date"
  >
    <template v-slot:iconRight>
      <StyledButton
        v-if="value"
        class="clear"
        variant="unstyled"
        icon="gk-x-icon"
        @click="$emit('clear')"
        tabindex="-1"
      />
    </template>
  </component>
</template>

<script>
import { StyledInput, StyledButton } from '.';

export default {
  name: 'GKDatepickerInput',
  extends: StyledInput,
  components: {
    StyledInput,
    StyledButton,
  },
  props: {
    inputComponent: {
      type: Object,
      default: () => StyledInput,
    },
  },
};
</script>

<style lang="scss" scoped>
.datepicker-input {
  position: relative;

  &:hover,
  &:focus-within {
    .clear {
      opacity: 1;
    }
  }
  .clear {
    background-color: transparent;
    &:not(&:disabled) {
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
