<template>
  <div class="g-thumbnail">
    <div class="thumbnail-wrapper" :class="[assetTypeName, { disabled }]">
      <div class="aspect-box" ref="thumbnail"></div>
      <div v-if="showThumbnail" class="preview" :style="{ backgroundImage: `url(${assetImageUrl})` }"></div>
      <slot v-if="showAssetIcon" name="assetTypeIcon" v-bind="{ assetType: assetTypeName }">
        <component :is="allTypeIcons[assetTypeName]" class="icon" :class="assetTypeName" />
      </slot>
      <slot name="icon" />
    </div>
    <div v-if="badge" class="badge" :class="[badge.toLowerCase().replace(' ', '-'), badgeClass]">{{ badge }}</div>
  </div>
</template>

<script>
import { IconPDF, IconPPT, IconImage, IconVideo, IconWebsite } from './assets/icons';
/**
 * @version 0.1.1
 */
export default {
  // TODO maybe add a loading state for images
  name: 'GThumbnail',
  props: {
    src: {
      type: String,
      default: null,
    },
    badge: {
      type: String,
      default: null,
    },
    badgeClass: {
      type: [String, Object, Array],
      default: null,
    },
    assetType: {
      type: String,
      default: null,
    },
    aspectRatio: {
      type: String,
      default: '16:9',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    typeIcons: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showAssetIcon: false,
      showThumbnail: !!this.src,
      placeholderSrc: 'https://picsum.photos/200?blur=10',
      imageSrc: null,
      imageLoading: false,
      imageFailed: false,
      localTypeIcons: {
        video: IconVideo,
        pdf: IconPDF,
        website: IconWebsite,
        image: IconImage,
        presentation: IconPPT,
      },
    };
  },
  computed: {
    assetTypeName() {
      return this.assetType?.toLowerCase();
    },
    assetImageUrl() {
      if (!this.imageSrc) return this.placeholderSrc;
      return this.imageSrc;
    },
    allTypeIcons() {
      return { ...this.localTypeIcons, ...this.typeIcons };
    },
  },
  mounted() {
    if (this.assetType && !this.src) {
      this.showThumbnail = false;
      this.showAssetIcon = true;
    }
    if (this.aspectRatio) {
      const { thumbnail } = this.$refs;
      if (thumbnail) {
        const ratio = this.aspectRatio.split(':');
        thumbnail.style.paddingBottom = `${(ratio[1] / ratio[0]) * 100}%`;
      }
    }
    if (this.src) {
      this.loadImageSrc();
    }
  },
  methods: {
    loadImageSrc() {
      const img = new Image();
      const _this = this;
      function onLoad() {
        _this.imageLoading = false;
        _this.imageSrc = this.src;
        _this.showAssetIcon = false;
        _this.showThumbnail = true;
        img.removeEventListener('load', this.onLoad);
      }
      img.addEventListener('load', onLoad);
      img.addEventListener('error', () => {
        this.imageFailed = true;
      });
      this.imageFailed = false;
      this.imageLoading = true;
      this.showAssetIcon = true;
      this.showThumbnail = false;
      img.src = this.src;
    },
  },
};
</script>

<style lang="scss">
.g-thumbnail {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  max-width: 250px;
  overflow: hidden;
  .thumbnail-wrapper {
    position: relative;
    color: white;
    background: lightgray;

    &.disabled {
      opacity: 0.5;
    }
  }

  .badge {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 10px;
    font-size: 1.5em;
    color: white;
    background-color: rgb(131, 212, 179);
  }

  .preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 35%;
    max-height: 55%;
    fill: currentColor;
    transform: translate(-50%, -50%);
    &.image {
      stroke: currentColor;
      stroke-width: 4px;
    }
  }
}
</style>
