export default {
  inject: {
    fieldError: {
      default: { value: false },
    },
    fieldDisabled: {
      default: { value: false },
    },
  },
  computed: {
    hasError() {
      return this.fieldError.value;
    },
  },
};
