<template>
  <component :is="'Icon'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="g-icon" :color="iconColor">
    <slot v-for="(_, name) in $slots" :name="name" />
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { Icon } from '@iconify/vue2';
import '../assets/g.iconify-bundle';
// import DarkMode from '@/components/DarkMode.vue';

export default {
  name: 'GIcon',
  inheritAttrs: false,
  // mixins: [DarkMode],
  extends: Icon,
  data() {
    return {};
  },
  components: {
    Icon,
  },
  computed: {
    iconColor() {
      if (this.$attrs.color) return this.$attrs.color;
      return null;
      // return this.isDark ? 'white' : 'black';
    },
  },
};
</script>

<style lang="scss" scoped>
.g-icon {
  //
}
</style>
