<template>
  <component :is="'GButton'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="gk-button">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GButton } from '@grimoire/base';

const component = {
  name: 'GKButton',
  extends: GButton,
  inheritAttrs: false,
  props: {
    colorVariants: {
      default: () => ['primary', 'danger'],
    },
  },
  components: {
    GButton,
  },
  methods: {},
};
export { component as default, component as StyledButton };
</script>

<style lang="scss" scoped>
.gk-button {
  --g-button-icon-gap: 10px;
  @include font-button;
  $variants: 'primary', 'danger';
  $colors: (
    'primary': $gk-grey,
    'primary--variant-filled--background-color_hover': $gk-grey,
    'primary--variant-filled--font-color': $gk-white,
    'primary--variant-outlined--font-color_hover': $gk-white,
    'primary--variant-text--font-color_hover': $gk-grey-2,
    'danger': $gk-red,
    'danger--variant-filled--background-color_hover': $gk-grey,
    'danger--variant-filled--font-color': $gk-white,
    'danger--variant-outlined--font-color_hover': $gk-red,
    'danger--variant-text--font-color_hover': darken($gk-red, 5%),
  );

  &.default {
    color: white;
    @each $variant in $variants {
      &.#{$variant} {
        color: map-get($colors, #{$variant + '--variant-filled--font-color'});
        background-color: map-get($colors, #{$variant});

        &:not(:disabled) {
          &:hover,
          &:focus-visible {
            background-color: map-get($colors, #{$variant + '--variant-filled--background-color_hover'});
          }
        }
      }
    }
  }
  &.outline {
    @each $variant in $variants {
      &.#{$variant} {
        color: map-get($colors, #{$variant});
        border-color: $gk-grey;

        &:not(:disabled) {
          &:hover,
          &:focus-visible {
            color: map-get($colors, #{$variant + '--variant-outlined--font-color_hover'});
            background-color: $gk-grey;
          }
        }
      }
    }
  }
  &.text {
    @each $variant in $variants {
      &.#{$variant} {
        color: map-get($colors, #{$variant});

        &:not(:disabled) {
          &:hover,
          &:focus-visible {
            color: map-get($colors, #{$variant + '--variant-text--font-color_hover'});
          }
        }
      }
    }
  }
  &:disabled {
    opacity: 0.5;
  }
}
</style>
