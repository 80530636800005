<template>
  <label class="g-checkbox" :class="{ disabled, error }">
    <input type="checkbox" v-bind="{ ...$attrs, disabled }" :value="value" v-model="model" v-on="localListeners" />
    <slot name="checkbox" v-bind="{ checked: isChecked }">
      <div class="checkbox" aria-hidden="true" focusable="false" :class="{ checked: isChecked }">
        <slot name="icon" v-bind="{ checked: isChecked }">
          <template v-if="isChecked">
            <GIcon
              v-if="typeof icon === 'string'"
              :icon="icon"
              class="icon-checkmark"
              :class="{ checked: isChecked }"
            />
            <Component v-else :is="icon" class="icon-checkmark" :class="{ checked: isChecked }" />
          </template>
        </slot>
      </div>
    </slot>
    <span v-if="hasLabel && !hideLabel" class="label" :class="labelPosition">
      <!-- @slot used for inserting label. Will default to label prop then value if label not provided -->
      <slot v-bind="{ checked: isChecked, label: computedLabel, value }">
        {{ computedLabel || value }}
      </slot>
    </span>
  </label>
</template>

<script>
/* eslint-disable object-shorthand */
// QUESTION should a select also use this format?
// QUESTION should this be part of the g-input component?

/**
 * @version 0.2.0
 */
import { GIcon } from '..';
import IconCheckmark from './assets/icons/checkmark.svg';

export default {
  name: 'GCheckbox',
  inheritAttrs: false,
  components: {
    GIcon,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    label: {
      type: [String, Function],
      default: null,
    },
    labelPosition: {
      type: String,
      default: 'after',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    checked: {
      type: [Array, Boolean],
      default: false,
    },
    value: {
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
      default: () => IconCheckmark,
    },
  },
  computed: {
    // I used this to make this work https://stackoverflow.com/questions/58187290/vue-binding-with-v-model-in-custom-checkbox-component-doesnt-work
    model: {
      get() {
        return this.checked;
      },
      set(checked) {
        this.$emit('change', checked);
        this.$emit('update:checked', checked);
        this.$emit('validate', checked);
      },
    },
    localListeners() {
      return {
        ...this.$listeners,
        change: (event) => this.$emit('updated', event),
      };
    },
    checkedIsArray() {
      return Array.isArray(this.checked);
    },
    isChecked() {
      if (this.checkedIsArray) {
        return this.checked.includes(this.value);
      }
      return this.checked;
    },
    computedLabel() {
      if (this.label instanceof Function) {
        return this.label(this.$props);
      }
      return this.label;
    },
    hasLabel() {
      return !!this.$scopedSlots.default || !!this.computedLabel || !!this.value;
    },
    buttonID() {
      return this.id || `g-checkbox-${this._uid}`;
    },
  },
};
</script>

<style lang="scss">
.g-checkbox {
  --g-checkbox-size: 24px;

  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
  .checkbox {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: var(--g-checkbox-size);
    height: var(--g-checkbox-size);
    border: 1px solid black;
    &.checked {
      background-color: blue;
    }
  }
  .icon-checkmark {
    display: block;
    width: 100%;
    color: white;
  }

  .label {
    line-height: var(--g-checkbox-size);
    &.after {
      margin-left: var(--g-checkbox-label-gap, 5px);
    }
    &.before {
      order: -1;
      margin-right: var(--g-checkbox-label-gap, 5px);
    }
  }

  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;

    /* tweak size and position if needed */
    width: var(--g-checkbox-size);
    height: var(--g-checkbox-size);
    padding: 0;
    margin: 0;
    cursor: inherit;

    /* hide it visually if 0 screen reader will skip allegedly haven't tested */
    opacity: 0.00001;

    // &:focus-visible {
    //   + .checkbox {
    //     position: relative;
    //     &::before {
    //       content: '';
    //       position: absolute;
    //       z-index: -1;
    //       width: 150%;
    //       height: 150%;
    //       padding: 20px;
    //       background-color: rgba(red, 1);
    //       border-radius: 50%;
    //     }
    //     // outline: 1px solid orange;
    //     // outline-offset: 2px;
    //   }
    // }
  }
}
</style>
